import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea'
import { red } from '@material-ui/core/colors';
import Alert from '../Alerts/Alerts.jsx';
import { userService } from '../../services/index';

import config from '../../config';

const { API_BASE_URL } = config;

const useStyles = makeStyles(theme => ({
    actions: {
        display: 'flex'
    },
    card: {
        maxWidth: 300,
        marginBottom: '1rem'
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    avatar: {
        backgroundColor: red[500]
    }
}));

const MediaItem = ({ data, onClick }) => {
    const classes = useStyles();
    const [createdBy, setCreatedBy] = useState(null);
    const [error, setError] = useState('');

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
    };

    /**
     * getUser
     * call userService and get a user who was a modified data
     */
    const getUser = useCallback(async (cancelToken) => {
        try {
            const res = await userService.getUser(data.modifiedBy, cancelToken);
            if (res && res.data && res.data) {
                setCreatedBy(res.data);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [data.modifiedBy]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        getUser({ cancelToken: source.token });
        return () => {
            source.cancel();
        };
    }, [getUser]);

    const handleClickOnCard = () => {
        if (onClick) {
            onClick(data);
        }
    };

    return (
        <>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}

            <Card className={classes.card}>
                <CardHeader
                    title={data.title}
                />
                <CardActionArea onClick={handleClickOnCard}>
                    <CardMedia
                        className={classes.media}
                        title={data.fileName}
                        image={`${API_BASE_URL}/api/media/preview/${data.id}`}
                        key={data.updatedAt}
                    />
                </CardActionArea>
                <CardContent>

                    <Typography variant="caption" color="textSecondary" component="p">
                        <strong>Modified by: </strong>
                        {`${createdBy && createdBy.name ? createdBy.name : ''}, ${createdBy && createdBy.username ? createdBy.username : ''}`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="p">
                        <strong>Modified at: </strong>
                        {data && data.updatedAt ? moment(data.updatedAt).format('DD.MM.YYYY.') : ''}
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
};

MediaItem.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func
};

export default React.memo(MediaItem);
