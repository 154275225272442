import React, { useEffect, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import Alert from '../Alerts/Alerts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import TextModal from '../TextModal/TextModal.jsx';
import { textService } from '../../services/index';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

import './TextTable.scss';

const useStyles = makeStyles({
    table: {
    // minWidth: 650,
    }
});

export default function TextTable() {
    const [page, setPage] = React.useState(1);
    // const [dense, setDense] = React.useState(false);
    const { sort, handleSort } = useSortHook('title');
    const [texts, setTexts] = useState({ texts: [] });

    const [textModalData, openTextModal] = useState({
        isOpen: false,
        text: null
    });

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [success, setSuccess] = useState(null);

    const { selectedLng } = useContext(
        LanguageContext
    );

    const [error, setError] = useState('');
    const classes = useStyles();

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(texts.count);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(texts.count);
    }, [handleNumberOfPages, texts.count]);

    /**
   * getAllTexts
   * call textService and get all texts by the selected language
   */
    const getAllTexts = useCallback(async () => {
        try {
            if (sort) {
                const lng = selectedLng && selectedLng.id ? selectedLng.id : '';
                let queryParams;
                if (lng) {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}&langId=${lng}`;
                } else {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                }
                const data = await textService.getAllText(queryParams);
                const newTexts = [...data.data.rows];
                setTexts(prevState => {
                    return {
                        ...prevState,
                        texts: newTexts,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [pageNumber, sort, selectedLng, setTexts]);

    useEffect(() => {
        getAllTexts();
        return () => {
        };
    }, [sort, page, getAllTexts, texts.texts.length]);

    const checkIsValid = (data) => {
        if (!data) {
            setError('All data are required');
            return false;
        } else if (!data.title) {
            setError('Title is required');
            return false;
        } else if (!data.key) {
            setError('Key is required');
            return false;
        }
        return true;
    };

    /**
   * addText
   * call textService and ad a new text object
   * @param {object} newData newData a text object that should be inserted
   */
    const addText = async (newData) => {
        try {
            if (!newData) {
                setError('All data are required');
                return false;
            }
            newData.langId = selectedLng.id;
            if (!newData.hasOwnProperty('active')) newData.active = 0;
            if (checkIsValid(newData)) {
                const newText = await textService.addText(newData);
                setTexts(prevState => {
                    const prevData = [...prevState.texts, newText.data];
                    return {
                        ...prevState,
                        texts: prevData,
                        count: prevState.count + 1
                    };
                });
                setError('');
                openTextModal({});
                successAlertMsg('Successfully added new text');
            }
        } catch (err) {
            openTextModal(null);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * deleteText
   * call textService and delete a text
   * @param {object} text text object that should be deleted
   */
    const deleteText = async (text) => {
        try {
            await textService.deleteText(text.id);
            setTexts(prevState => {
                const textList = [...prevState.texts.filter(item => item.id !== text.id)];
                if (textList.length === 0 && page > 0) handleChangePage(null, page - 1);
                return {
                    texts: textList,
                    count: prevState.count - 1
                };
            });
            setError('');
            openTextModal({});
            handleCancel();
            successAlertMsg(`Successfully deleted text: ${text.title}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
            handleCancel();
        }
    };

    const onDeleteText = (data) => {
        setOpenConfirmationModal(() => {
            return {
                title: 'Delete text',
                message: `Are you sure you want delete ${data.title}`,
                isOpen: true,
                data: data,
                handleSubmit: deleteText,
                handleClose: handleCancel
            };
        });
    };

    const handleCancel = () => {
        setOpenConfirmationModal(null);
    };

    /**
   * updateText
   * call textService and update text
   * @param {object} text text object that should be updated
   */
    const updateText = async (text) => {
        try {
            if (checkIsValid(text)) {
                const updatedText = await textService.editText(text.id, text);
                setTexts(prevState => {
                    const prevTexts = [...prevState.texts];
                    return {
                        ...prevState,
                        texts: prevTexts.map(item => item.id === text.id ? updatedText.data : item)
                    };
                });
                openTextModal({});
                setError('');
                successAlertMsg(`Successfully updated text: ${text.title}`);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    const closeAddUserModal = () => {
        openTextModal({});
    };

    const handleSubmit = (text) => {
        if (textModalData && textModalData.text) {
            updateText(text);
        } else {
            addText(text);
        }
    };

    /**
   * handleOpenTextModal
   * open add/edit modal
   * @param {object} text text object that should be edited
   */
    const handleOpenTextModal = (text) => {
        if (!selectedLng || !selectedLng.id) {
            setError('Please select a language.');
            return;
        }
        if (text) {
            openTextModal(() => {
                return {
                    isOpen: true,
                    text: text
                };
            });
        } else {
            openTextModal(() => {
                return {
                    isOpen: true,
                    text: null
                };
            });
        }
    };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null}
            <Button className="add-text-btn" size="small" variant="outlined" color="default" onClick={() => handleOpenTextModal()}>Add</Button>
            <TextModal {...textModalData} onClose={closeAddUserModal} onSubmit={handleSubmit} />
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => { handleSort('id'); handleChangePage(null, 1); }} align="left">ID</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active'); handleChangePage(null, 1); }} align="left">Active</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('title'); handleChangePage(null, 1); }} align="right">Title</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('key'); handleChangePage(null, 1); }} align="right">Key</TableCell>
                        <TableCell align="right">Modified By</TableCell>
                        <TableCell align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {texts.texts.map(row => (
                        <TableRow key={row.id}>
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell component="th" scope="row">
                                {row.active ? 'Active' : 'Inactive'}
                            </TableCell>
                            <TableCell align="right">{row.title}</TableCell>
                            <TableCell align="right">{row.key}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => handleOpenTextModal(row)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                <Button size="small" onClick={() => onDeleteText(row)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>

        </React.Fragment>
    );
}
