/**
 * Check is value a number
 * @param {num} num number
 */
export const isNumber = (num) => {
    if (typeof num !== 'number') return false;
    if (num !== Number(num)) return false;
    if (Number.isFinite(num) === false) return false;
    return true;
};
