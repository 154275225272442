import { useState, useEffect, useCallback } from 'react';
import { DEFAULT_ROWS_PER_PAGE } from '../constants';

const usePaginationHook = (count, rowsPerPage) => {
    const [numberOfPages, setNumberOfPages] = useState(0);

    const handleNumberOfPages = useCallback((count) => {
        const rows = rowsPerPage || DEFAULT_ROWS_PER_PAGE;
        if (!count || count <= rows) {
            setNumberOfPages(0);
        } else {
            const pageCounter = Math.ceil(count / rows);
            setNumberOfPages(pageCounter);
        }
    }, [rowsPerPage]);

    useEffect(() => {
        handleNumberOfPages(count);
    }, [count, handleNumberOfPages]);

    return { numberOfPages, handleNumberOfPages };
};

export default usePaginationHook;
