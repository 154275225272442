import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function LanguageModal({
    onClose,
    isOpen = false,
    language,
    onSubmit,
    title
}) {
    // const [open, setOpen] = React.useState(false);
    const [languageData, setLanguageData] = useState({
        name: '',
        acronym: '',
        active: 0
    });

    React.useEffect(() => {
        setLanguageData(language);
    }, [language]);

    const handleChange = e => {
        e.persist();
        setLanguageData(prevSate => {
            const newState = { ...prevSate };
            if (!newState.hasOwnProperty('active')) newState.active = 0;
            return {
                ...newState,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(languageData);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                        {title || ''}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="name"
                            label="Full name"
                            type="text"
                            name="name"
                            value={languageData && languageData.name ? languageData.name : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            id="acronym"
                            label="Acronym"
                            type="text"
                            name="acronym"
                            value={languageData && languageData.acronym ? languageData.acronym : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={languageData && languageData.active ? languageData.active : 0}
                            onChange={handleChange}
                            name="active"
                            style={{ marginTop: '10px' }}
                        // fullWidth
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
            Cancel
                        </Button>
                        <Button type="submit" color="primary">
            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

LanguageModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    language: PropTypes.object,
    onSubmit: PropTypes.func,
    title: PropTypes.string
};
