import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function CreateUser({
    onClose,
    isOpen = false,
    user,
    onSubmit
}) {
    const [userData, setUserData] = useState({
        name: '',
        username: '',
        email: '',
        lvl: 1,
        password: '',
        confirmedPassword: '',
        active: 0
    });

    React.useEffect(() => {
        setUserData(user);
    }, [user]);

    const handleChange = e => {
        e.persist();
        setUserData(prevSate => {
            const newState = { ...prevSate };
            if (!newState.hasOwnProperty('lvl')) newState.lvl = 1;
            if (!newState.hasOwnProperty('active')) newState.active = 0;
            return {
                ...newState,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(userData);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                        {user ? 'Edit user' : 'Add user'}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="name"
                            label="Full name"
                            type="text"
                            name="name"
                            value={userData && userData.name ? userData.name : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            id="username"
                            label="Username"
                            type="text"
                            name="username"
                            value={userData && userData.username ? userData.username : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            id="email"
                            label="Email"
                            type="email"
                            name="email"
                            value={userData && userData.email ? userData.email : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        {!user ? (
                            <>
                                <TextField
                                    margin="normal"
                                    // required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={userData && userData.password ? userData.password : ''}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="normal"
                                    id="confirmerdPassword"
                                    label="Confirm Password"
                                    type="password"
                                    name="confirmedPassword"
                                    fullWidth
                                    value={
                                        userData && userData.confirmedPassword
                                            ? userData.confirmedPassword
                                            : ''
                                    }
                                    onChange={handleChange}
                                />
                            </>
                        ) : null}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userData && userData.lvl ? userData.lvl : 1}
                            onChange={handleChange}
                            name="lvl"
                        // fullWidth
                        >
                            <MenuItem value={1}>Admin</MenuItem>
                            <MenuItem value={2}>User</MenuItem>
                        </Select>
                        <br />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userData && userData.active ? userData.active : 0}
                            onChange={handleChange}
                            name="active"
                            style={{ marginTop: '10px' }}
                        // fullWidth
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
            Cancel
                        </Button>
                        <Button type="submit" color="primary">
            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

CreateUser.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    user: PropTypes.object,
    onSubmit: PropTypes.func
};
