import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loading.scss';

const Loading = () => {
    return (
        <div className="loader-wrapper">
            <div className="loader-spinner">
                <CircularProgress size="5rem"/>
            </div>
        </div>
    );
};

export default Loading;
