import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TablePagination from '@material-ui/core/TablePagination';
import Alert from '../Alerts/Alerts.jsx';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import { cssService } from '../../services/index';
import CSSItem from './CSSItem';
import '../FileList/FileList.scss';

export default function CSSModal({
    onClose,
    isOpen = false,
    title,
    onCSSClick
}) {
    // const [open, setOpen] = React.useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.name === 'title') setSearchTitle(e.target.value);
    };

    const handleSubmit = useCallback((media) => {
        onCSSClick(media);
        onClose();
    }, [onCSSClick, onClose]);

    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [searchTitle, setSearchTitle] = useState('');

    const [files, setFiles] = useState({ files: [] });

    const [error, setError] = useState('');

    const countRows = useRef();

    const { selectedLng } = useContext(LanguageContext);

    /**
   * getAllFiles
   * call assetService and get all backgrounds by the selected language
   */
    const getAllFiles = useCallback(async () => {
        try {
            let queryParams = `?page=${page}`;
            queryParams += '&pageSize=20';
            queryParams += `&langId=${selectedLng.id}`;
            if (searchTitle) queryParams += `&title=${searchTitle}`;
            const data = await cssService.getAllCSS(queryParams);
            const newFiles = [...data.data.rows];
            if (countRows.current !== data.data.count) {
                setPage(0);
            }
            setFiles(prevState => {
                countRows.current = data.data.count;
                return {
                    ...prevState,
                    files: newFiles,
                    count: data.data.count
                };
            });
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [page, searchTitle, selectedLng]);

    useEffect(() => {
        getAllFiles();
    }, [page, getAllFiles, files.files.length, searchTitle]);

    const handleChangePage = (event, newData) => {
        setPage(newData);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">
                    {title || ''}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
                        <Grid container>
                            <Grid container justify="space-between">
                                <Paper component="form" className="paper-wrapper">
                                    <Grid container justify="space-between">
                                        <Grid>
                                            <InputBase
                                                placeholder="Search"
                                                onChange={handleChange}
                                                name="title"
                                            />
                                            <IconButton onClick={handleChange} aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {
                                files.files.map(item => {
                                    return (
                                        <Grid key={item.id} item md={4} lg={2} style={{ marginTop: '2rem', marginLeft: '1rem', marginRight: '1rem' }}>
                                            <CSSItem onClick={handleSubmit} key={item.id} data={item} />
                                        </Grid>
                                    );
                                })
                            }
                            <Grid container justify="flex-end">
                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[10]}
                                    colSpan={6}
                                    count={files.count || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
            Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CSSModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    onCSSClick: PropTypes.func
};
