import React, { useEffect, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import Alert from '../Alerts/Alerts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
// import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import LanguageModal from '../LanguageModal/LanguageModal.jsx';
import { languageService } from '../../services/index';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

import './LanguagesTable.scss';

const useStyles = makeStyles({
    table: {
    // minWidth: 650,
    }
});

export default function LanguagesTable() {
    const [page, setPage] = React.useState(1);
    const { sort, handleSort } = useSortHook('acronym');
    const [allLanguages, setAllLanguages] = useState({ languages: [] });

    const [languageModalData, openLanguageModal] = useState({
        isOpen: false,
        language: null
    });

    // const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [success, setSuccess] = useState(null);

    const [error, setError] = useState('');
    const classes = useStyles();

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(allLanguages.count);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(allLanguages.count);
    }, [handleNumberOfPages, allLanguages.count]);

    const { setLanguages, selectedLng, setSelectedLanguage } = useContext(
        LanguageContext
    );

    /**
   * getAllLanguages
   * call languageService and get all languages
   */
    const getAllLanguages = useCallback(async () => {
        try {
            if (sort) {
                const queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                const data = await languageService.getAllLanguages(queryParams);
                const newLanguages = [...data.data.rows];
                setAllLanguages(prevState => {
                    return {
                        ...prevState,
                        languages: newLanguages,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else {
                setError(err.response.statusText);
            }
        }
    }, [sort, pageNumber]);

    useEffect(() => {
        getAllLanguages();
    }, [sort, page, getAllLanguages, allLanguages.languages.length]);

    const checkIsValid = (data) => {
        if (!data) {
            setError('All data are required');
            return false;
        } else if (!data.name) {
            setError('Name is required');
            return false;
        } else if (!data.acronym) {
            setError('Acronym is required');
            return false;
        }
        return true;
    };

    /**
   * addNewLanguage
   * call languageService and add a new language
   * @param {object} newData newData a language object ready for inserting to DB
   */
    const addLanguage = async (newData) => {
        try {
            if (checkIsValid(newData)) {
                const data = await languageService.addLanguage(newData);
                setAllLanguages(prevState => {
                    const newLanguages = [...prevState.languages, data.data];
                    return {
                        ...prevState,
                        languages: newLanguages,
                        count: prevState.count + 1
                    };
                });
                // update context
                setLanguages(prevS => {
                    return {
                        ...prevS,
                        languages: [...prevS, data.data]
                    };
                });
                setError('');
                openLanguageModal({});
                successAlertMsg('Successfully added new language');
            }
        } catch (err) {
            openLanguageModal({});
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * deleteLanguage
   * call languageService and delete language
   * @param {object} language language object that should be deleted
   */
    // const deleteLanguage = async (language) => {
    //     try {
    //         await languageService.deleteLanguage(language.id);
    //         setAllLanguages(prevState => {
    //             return {
    //                 languages: [...prevState.languages.filter(item => item.id !== language.id)],
    //                 count: prevState.count - 1
    //             };
    //         });
    //         // update context
    //         setLanguages(prevS => {
    //             return {
    //                 languages: [...prevS.filter(item => item.id !== language.id)]
    //             };
    //         });
    //         if (language && language.id && selectedLng && selectedLng.id === language.id) setSelectedLanguage(null);
    //         setError('');
    //         handleCancel();
    //         successAlertMsg(`Successfully deleted language: ${language.name}`);

    //     } catch (err) {
    //         if (err && err.response && err.response.data  && err.response.data.error && err.response.data.error.message) {
    //             setError(err.response.data.error.message);
    //         } else if (err.response && err.response.statusText) {
    //             setError(err.response.statusText);
    //         }
    //     }
    // };

    /**
   * updateLanguage
   * call languageService and update language
   * @param {object} language a language object that should be updated
   */
    const updateLanguage = async (language) => {
        if (!language) {
            setError('Missing language');
            return;
        }
        if (language.acronym === 'en_uk' && language.active === false) {
            setError('Can not set inactive to default language.');
            return;
        }
        try {
            const updatedLng = await languageService.editLanguage(language.id, language);
            setAllLanguages(prevState => {
                const prevLanguages = [...prevState.languages];
                return {
                    ...prevState,
                    languages: prevLanguages.map(item => item.id === language.id ? updatedLng.data : item)
                };
            });
            // update context
            if (updatedLng && updatedLng.data && updatedLng.data.id && selectedLng &&
        selectedLng.id && selectedLng.id === updatedLng.data.id) {
                setSelectedLanguage(updatedLng.data);
            }
            setLanguages(prevS => {
                return {
                    ...prevS,
                    languages: [...prevS.map(item => item.id === language.id ? updatedLng.data : item)]
                };
            });
            openLanguageModal({});
            successAlertMsg(`Successfully updated language: ${language.name}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * editLanguageModal
   * open edit language modal
   * @param {object} language language object that should be edited
   */
    const editLanguageModal = (language) => {
        openLanguageModal(() => {
            return {
                isOpen: true,
                language: language,
                title: 'Edit language'
            };
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    const closeAddUserModal = () => {
        openLanguageModal({});
    };

    const handleSubmit = (user) => {
        if (languageModalData && languageModalData.language) {
            updateLanguage(user);
        } else {
            addLanguage(user);
        }
    };

    const addLanguageModal = () => {
        openLanguageModal(() => {
            return {
                isOpen: true,
                language: null,
                title: 'Add language'
            };
        });
    };

    // const onDeleteText = (data) => {
    //     setOpenConfirmationModal(prevState => {
    //         return {
    //             title: 'Delete language',
    //             message: `Are you sure you want delete ${data.name}`,
    //             isOpen: true,
    //             data: data,
    //             handleSubmit: deleteLanguage,
    //             handleClose: handleCancel
    //         };
    //     });
    // };

    // const handleCancel = () => {
    //     setOpenConfirmationModal(null);
    // };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            {/* {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null} */}
            <LanguageModal {...languageModalData} onClose={closeAddUserModal} onSubmit={handleSubmit} />
            <Button className="add-lng-btn" size="small" variant="outlined" color="default" onClick={addLanguageModal}>Add</Button>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => { handleSort('id'); handleChangePage(null, 1); }} align="left">ID</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('name'); handleChangePage(null, 1); }} align="left">Name</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('acronym'); handleChangePage(null, 1); }} align="right">Acronym</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active'); handleChangePage(null, 1); }} align="right">Active</TableCell>
                        <TableCell align="right">Modified By</TableCell>
                        <TableCell align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allLanguages.languages.map(row => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.acronym}</TableCell>
                            <TableCell align="right">{row.active === 1 ? 'Active' : 'Inactive'}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => editLanguageModal(row)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                {/* <Button size="small" onClick={() => onDeleteText(row)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button> */}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>

        </React.Fragment>
    );
}
