import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PageEditor from '../PageEditor/PageEditor.jsx';
import PageAssetsTable from '../PageAssetsTable/PageAssetsTable.jsx';
// import PageCssTable from '../PageCssTable/PageCssTable.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '../Alerts/Alerts.jsx';
import { pageService } from '../../services/page.services';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import MiscModal from '../MiscModal/MiscModal.jsx';
import CSSModal from '../CSSModal/CSSModal.jsx';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    }
}));

export default function AddNewPageEditor() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { selectedLng } = useContext(
        LanguageContext
    );

    const history = useHistory();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [pageCode, setPageCode] = useState('');
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState('');

    const [title, setTitle] = useState('');
    const [isActive, setIsActive] = useState(0);
    const [key, setKey] = useState('');
    const [externalUrl, setExternalUrl] = useState('');
    const [assets, setAssets] = useState({ assets: [] });
    const [csses, setCSSes] = useState({ csses: [] });

    const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
    const [isOpenCSSModal, setIsOpenCSSModal] = useState(false);

    const openMediaModal = () => {
        setIsOpenMediaModal(true);
    };

    // const openCSSModal = () => {
    //     setIsOpenCSSModal(true);
    // };

    const closeMediaModal = () => {
        setIsOpenMediaModal(false);
    };

    const closeCSSModal = () => {
        setIsOpenCSSModal(false);
    };

    const onMediaCardClick = useCallback((asset) => {
        setAssets(prevState => {
            const existingAsset = prevState.assets.filter(one => one.id === asset.id);
            if (!existingAsset || existingAsset.length === 0) prevState.assets.push(asset);
            return {
                ...prevState
            };
        });
    }, [setAssets]);

    const onCSSClick = useCallback((css) => {
        setCSSes(prevState => {
            const existingCSSes = prevState.csses.filter(one => one.id === css.id);
            if (!existingCSSes || existingCSSes.length === 0) prevState.csses.push(css);
            return {
                ...prevState
            };
        });
    }, [setCSSes]);

    const onAssetDelete = useCallback((media) => {
        setAssets(prevState => {
            const newAssets = prevState.assets.filter(one => one.id !== media.id);
            return {
                ...prevState,
                assets: newAssets
            };
        });
    }, [setAssets]);

    // const onCSSDelete = useCallback((css) => {
    //     setCSSes(prevState => {
    //         const newCSSes = prevState.csses.filter(one => one.id !== css.id);
    //         return {
    //             ...prevState,
    //             csses: newCSSes
    //         };
    //     });
    // }, [setCSSes]);

    /**
   * savePage
   * call pageService and create a new page
   */
    const savePage = async () => {
        try {
            const page = {
                title,
                langId: selectedLng && selectedLng.id ? selectedLng.id : null,
                content: pageCode ? pageCode.trim() : '',
                active: isActive,
                assets: assets.assets,
                csses: csses.csses,
                key,
                externalUrl: externalUrl ? externalUrl.trim() : ''
            };
            if (checkIsValidPage(page)) {
                const newPage = await pageService.addPage({ title, langId: selectedLng.id, content: pageCode, active: isActive, csses: csses.csses, assets: assets.assets, key, externalUrl });
                clearAllInputs();
                setError('');
                successAlertMsg(`Successfully created a new page: ${newPage && newPage.data && newPage.data.title ? newPage.data.title : ''}`);
                history.push('/dashboard/pages');
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else {
                setError(err.response.statusText);
            }
        }
    };

    const checkIsValidPage = page => {
        if (!page.title) {
            setError('Missing page title');
            return false;
        } else if (!page.langId) {
            setError('Not selected language');
            return false;
        } else if (!page.content && !page.externalUrl) {
            setError('Missing page content or external URL');
            return false;
        } else if (!page.key) {
            setError('Missing page key');
            return false;
        }
        return true;
    };

    /**
   * successAlertMessage opens a alert component and display a message
   * @param {string} msg message that would be shown to the user
   */
    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    /**
   * clearAllInputs
   * clear Quill and text input fields
   */
    const clearAllInputs = () => {
        setPageCode('');
        setTitle('');
        setIsActive(0);
    };

    /**
   * handleInputChange
   * handle on change textarea and update a state
   * @param {event} e an input event
   */
    const handleInputChange = useCallback(e => {
        setPageCode(e.target.value);
    }, [setPageCode]);

    /**
   * useEffect event listening for the editor change
   */
    useEffect(() => {
        setPageCode(pageCode);
        sessionStorage.setItem('htmlPage', pageCode)
    }, [setPageCode, pageCode]);

    const handleTitle = useCallback(e => {
        setTitle(e.target.value);
    }, [setTitle]);

    const handleKey = useCallback(e => {
        setKey(e.target.value);
    }, [setKey]);

    const handleExternalUrl = useCallback(e => {
        setExternalUrl(e.target.value);
    }, [setExternalUrl]);

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = (event, reason) => {
        setError('');
        setSuccess(null);
    };

    const handleIsActive = (e) => {
        setIsActive(e.target.value);
    };

    const onCancel = () => {
        history.push('/dashboard/pages');
    };

    return (
        <div className={classes.root}>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen} handleClose={closeAlertMessage} /> : null}
            <AppBar position="static" color="default">
                <Grid container alignItems="center">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Page editor" {...a11yProps(0)} />
                        <Tab label="Assets" {...a11yProps(1)} />
                        {/* <Tab label="CSS" {...a11yProps(2)} /> */}
                    </Tabs>
                    <Grid item>
                        <Link to="/dashboard/page-preview" target="_blank" className="page-preview-link">
                            CODE PREVIEW
                            
                        </Link>
                    </Grid>
                </Grid>
            </AppBar>
            <Grid style={{ marginTop: '1rem' }} container
                direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item>
                    <TextField className="input-page-title" onChange={handleTitle} label="Title" value={title} />

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={isActive}
                        onChange={handleIsActive}
                        name="active"
                        style={{ marginTop: '1rem', marginLeft: '2rem' }}
                    // fullWidth
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                    <TextField className="input-page-title" onChange={handleKey} label="Key" value={key} />
                    <TextField className="input-page-external-url" onChange={handleExternalUrl} label="External URL" value={externalUrl} />
                    <Button onClick={openMediaModal}>
                        Add assets
                    </Button>
                    {/* <Button variant="contained" color="primary" onClick={openCSSModal}>
                        Add CSS
                    </Button> */}
                </Grid>
                <Grid item>
                    <Button className="save-page-btn" size="small" variant="outlined" color="default" onClick={savePage}>Save</Button>
                    <Button className="save-page-btn" size="small" variant="outlined" color="default" onClick={onCancel}>Cancel</Button>
                </Grid>
            </Grid>

            {isOpenMediaModal ? <MiscModal onMediaCardClick={onMediaCardClick} isOpen={isOpenMediaModal} onClose={closeMediaModal} /> : null}
            {isOpenCSSModal ? <CSSModal onCSSClick={onCSSClick} isOpen={isOpenCSSModal} onClose={closeCSSModal} /> : null}

            <TabPanel value={value} index={0}>
                <PageEditor editorHtml={pageCode} handleChange={handleInputChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <PageAssetsTable assets={assets.assets} onDelete={onAssetDelete}/>
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                <PageCssTable css={csses.csses} onDelete={onCSSDelete} />
            </TabPanel> */}
        </div>
    );
}
