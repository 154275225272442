import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import SideNav from '../components/SideNav/SideNav.jsx';
import { sessionService } from '../sessionServices/sessionServices';

const PrivateLayout = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const nav = rest.path !== '/change-password' ? (
                    <SideNav isAdmin={sessionService.isAdmin()}>
                        <Component {...props} />
                    </SideNav>
                ) : <Component {...props} />;
                return sessionService.isAuth() !== false ? (

                // <SideNav>
                //   <Component {...props} />
                // </SideNav>
                    nav

                ) : (
                    <Redirect to="/login" />
                );
            }}
        />
    );
};

export default PrivateLayout;
