import axiosAPI from '../httpService/httpAxiosService';

export const pageService = {
    addPage,
    editPage,
    deletePage,
    getAllPages,
    getPage
};

function addPage(data) {
    return axiosAPI.post('/api/page', data);
}

function editPage(id, data) {
    return axiosAPI.post(`/api/page/${id}`, data);
}

function deletePage(id) {
    return axiosAPI.delete(`/api/page/${id}`);
}

function getAllPages(queryParams) {
    return axiosAPI.get(`/api/page${queryParams}`);
}

function getPage(id, cancelToken) {
    return axiosAPI.get(`/api/page/${id}`, cancelToken);
}