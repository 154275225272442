import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';


const PageEditor = ({ handleChange, editorHtml = '' }) => {

    return (
        <React.Fragment>
            <TextareaAutosize
                className="page-preview"
                width={1}
                defaultValue={editorHtml}
                rowsMin={10}
                onChange={handleChange}
            />
        </React.Fragment>
    );
};

PageEditor.propTypes = {
    handleChange: PropTypes.func,
    editorHtml: PropTypes.string
};
export default PageEditor;
