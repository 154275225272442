import React, { useEffect, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import Alert from '../Alerts/Alerts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import { assetService } from '../../services/index';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import AssetModal from '../AssetModal/AssetModal.jsx';
import { ASSET_CATEGORIES, DEFAULT_ROWS_PER_PAGE } from '../../constants';
import config from '../../config';
import { isNumber } from '../../utils/number.utils';
import './BackgroundTable.scss';

const useStyles = makeStyles({
    table: {
    // minWidth: 650,
    }
});

export default function BackgroundTable() {
    const [page, setPage] = React.useState(1);
    // const [dense, setDense] = React.useState(false);
    const { sort, handleSort } = useSortHook('title');
    const [backgrounds, setBackgrounds] = useState({ backgrounds: [] });

    const [backgroundModalData, openBackgroundModal] = useState({
        isOpen: false,
        background: null
    });

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [success, setSuccess] = useState(null);

    const { selectedLng } = useContext(
        LanguageContext
    );

    const [error, setError] = useState('');
    const classes = useStyles();

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(backgrounds.count);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(backgrounds.count);
    }, [handleNumberOfPages, backgrounds.count]);

    /**
   * getAllBackgrounds
   * call assetService and get all backgrounds by the selected language
   */
    const getAllBackgrounds = useCallback(async () => {
        try {
            if (sort) {
                const lng = selectedLng && selectedLng.id ? selectedLng.id : '';
                let queryParams;
                if (lng) {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&category=${ASSET_CATEGORIES.BACKGROUND}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}&langId=${lng}`;
                } else {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&category=${ASSET_CATEGORIES.BACKGROUND}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                }
                const data = await assetService.getAllAssets(queryParams);
                const newBackgrounds = [...data.data.rows];
                setBackgrounds(prevState => {
                    return {
                        ...prevState,
                        backgrounds: newBackgrounds,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [pageNumber, sort, selectedLng, setBackgrounds]);

    useEffect(() => {
        getAllBackgrounds();
        return () => {
        };
    }, [sort, pageNumber, getAllBackgrounds, backgrounds.backgrounds.length]);

    const checkIsValid = (data) => {
        if (!data) {
            setError('All data are required');
            return false;
        } else if (!data.title) {
            setError('Missing title');
            return false;
        } else if (!isNumber(data.brushesNeeded)) {
            setError('Counter missing');
            return false;
        } else if (!(data.active > -1) && !(data.active < 2)) {
            setError('Active is not set');
            return false;
        } else if (!data.mediaId) {
            setError('Media not selected');
            return false;
        }
        return true;
    };

    /**
   * addBackground
   * call assetService and ad a new background object
   * @param {object} newData newData a background object that should be inserted
   */
    const addBackground = async (newData) => {
        try {
            if (!newData) {
                setError('All data are required.');
                return;
            }
            if (!selectedLng || !selectedLng.acronym) {
                setError('Please select a language');
                return;
            }
            newData.type = 0;
            newData.category = ASSET_CATEGORIES.BACKGROUND;
            newData.langId = selectedLng.id;
            if (checkIsValid(newData)) {
                newData.brushesNeeded = newData.brushesNeeded.toString(); newData.brushesNeeded = newData.brushesNeeded.toString();
                const newBackground = await assetService.addAsset(newData);
                setBackgrounds(prevState => {
                    const prevData = [...prevState.backgrounds, newBackground.data];
                    return {
                        ...prevState,
                        backgrounds: prevData,
                        count: prevState.count + 1
                    };
                });
                setError('');
                openBackgroundModal({});
                getAllBackgrounds();
                successAlertMsg('Successfully added new background');
            }
        } catch (err) {
            // openBackgroundModal(null);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * deleteBackground
   * call assetService and delete a background
   * @param {object} page background object that should be deleted
   */
    const deleteBackground = async (background) => {
        try {
            await assetService.deleteAsset(background.id);
            setBackgrounds(prevState => {
                const backgroundList = [...prevState.backgrounds.filter(item => item.id !== background.id)];
                if (backgroundList.length === 0 && page > 0) handleChangePage(null, page - 1);
                return {
                    backgrounds: backgroundList,
                    count: prevState.count - 1
                };
            });
            setError('');
            openBackgroundModal({});
            handleCancel();
            getAllBackgrounds();
            successAlertMsg(`Successfully deleted background: ${background.title}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
            handleCancel();
        }
    };

    const onDeleteBackground = (data) => {
        setOpenConfirmationModal(prevState => {
            return {
                title: 'Delete background',
                message: `Are you sure you want delete ${data.title}`,
                isOpen: true,
                data: data,
                handleSubmit: deleteBackground,
                handleClose: handleCancel
            };
        });
    };

    const handleCancel = () => {
        setOpenConfirmationModal(null);
    };

    /**
   * updateBackground
   * call assetService and update background
   * @param {object} data background object that should be updated
   */
    const updateBackground = async (data) => {
        try {
            if (checkIsValid(data)) {
                data.type = 0;
                data.langId = selectedLng.id;
                data.brushesNeeded = data.brushesNeeded.toString();
                const updatedBackground = await assetService.editAsset(data.id, data);
                setBackgrounds(prevState => {
                    const prevBackgrounds = [...prevState.backgrounds];
                    return {
                        ...prevState,
                        backgrounds: prevBackgrounds.map(item => item.id === data.id ? updatedBackground.data : item)
                    };
                });
                openBackgroundModal({});
                setError('');
                getAllBackgrounds();
                successAlertMsg(`Successfully updated background: ${data.title}`);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    const closeBackgroundModal = () => {
        openBackgroundModal({});
    };

    const handleSubmit = (data) => {
        if (backgroundModalData && backgroundModalData.data) {
            updateBackground(data);
        } else {
            addBackground(data);
        }
    };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    /**
   * backgroundModal
   * open backgroundModal
   */
    const backgroundModal = (background) => {
        if (!selectedLng || !selectedLng.id) {
            setError('Please select a language.');
            return;
        }
        if (!background) {
            openBackgroundModal(() => {
                return {
                    isOpen: true,
                    data: null,
                    title: 'Add new background',
                    coverPhoto: 'Background cover photo',
                    coverFile: 'Background file'
                };
            });
        } else {
            openBackgroundModal(prevState => {
                return {
                    ...prevState,
                    isOpen: true,
                    data: background,
                    title: 'Edit background',
                    coverPhoto: 'Background cover photo',
                    coverFile: 'Background file'
                };
            });
        }
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null}
            <Button className="add-text-btn" size="small" variant="outlined" color="default" onClick={() => backgroundModal()}>Add</Button>
            { backgroundModalData && backgroundModalData.isOpen ? <AssetModal {...backgroundModalData} onClose={closeBackgroundModal} onSubmit={handleSubmit} /> : null }
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => handleSort('id')}>ID</TableCell>
                        <TableCell>Preview</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active'); handleChangePage(null, 1); }} align="left">Active</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('title'); handleChangePage(null, 1); }} align="right">Title</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('brushesNeeded'); handleChangePage(null, 1); }} align="right">Counter</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('modifiedBy'); handleChangePage(null, 1); }} align="right">Modified By</TableCell>
                        <TableCell align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {backgrounds.backgrounds.map(row => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                <Avatar alt="Aquafresh cover photo"
                                    src={config.API_BASE_URL + '/api/media/preview/' + row.mediaId} style={{ marginRight: '.5rem' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.active ? 'Active' : 'Inactive'}
                            </TableCell>
                            <TableCell align="right">{row.title}</TableCell>
                            <TableCell align="right">{row.brushesNeeded}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => backgroundModal(row)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                <Button size="small" onClick={() => onDeleteBackground(row)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>

        </React.Fragment>
    );
}
