import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function TextModal({
    onClose,
    isOpen = false,
    text,
    onSubmit
}) {
    const [textData, setTextData] = useState({
        title: '',
        key: '',
        active: 0,
        langId: ''
    });

    React.useEffect(() => {
        setTextData(text);
    }, [text]);

    const handleChange = e => {
        e.persist();
        setTextData(prevSate => {
            return {
                ...prevSate,
                [e.target.name]: e.target.value
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(textData);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                        {textData && textData.id ? 'Edit text' : 'Add text'}
                    </DialogTitle>
                    <DialogContent>
                        <TextareaAutosize
                            autoFocus
                            margin="normal"
                            placeholder="Title"
                            id="title"
                            label="Title"
                            name="title"
                            value={textData && textData.title ? textData.title : ''}
                            style={{width: '100%'}}
                            onChange={handleChange}
                            rowsMin={3}
                        />
                        <TextField
                            margin="normal"
                            id="acronym"
                            label="Key"
                            type="text"
                            name="key"
                            value={textData && textData.key ? textData.key : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={textData && textData.hasOwnProperty('active') ? textData.active : 0}
                            onChange={handleChange}
                            name="active"
                        // fullWidth
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
            Cancel
                        </Button>
                        <Button type="submit" color="primary">
            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

TextModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    text: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
};
