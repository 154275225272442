import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { StylesProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import DanceTable from '../../components/DanceTable/DanceTable.jsx';

const DancePage = () => {
    const useStyles = makeStyles(theme => ({
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidthLg: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column'
        },
        fixedHeight: {
            height: 240
        },
        wrapper: {
            width: '100% !important'
        }
    }));
    const classes = useStyles();
    const { selectedLng } = useContext(
        LanguageContext
    );

    return (
        <StylesProvider>
            <main className="users-wrapper">
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Typography variant="h5" gutterBottom>
              Dance list {selectedLng && selectedLng.name ? ` for ${selectedLng.name} language` : ''}
                        </Typography>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className="" style={{ overflowX: 'auto' }}>
                                <DanceTable />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </StylesProvider>
    );
};

export default DancePage;
