import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { userService } from '../../services/index';
import { sessionService } from '../../sessionServices/sessionServices';
import { UserContext } from '../../contexts/userContext.jsx';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            Aquafresh
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function SingleSignOn() {
    const classes = useStyles();
    const [error, setError] = useState('');
    const [, setUser] = useContext(UserContext);
    const history = useHistory();

    const onSso = useCallback(async (code) => {
        try {
            const { data } = await userService.ssoLogin(code);
            if (data) {
                sessionService.create(data);
                setUser(data.user);
                history.push('/dashboard');
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [setUser, history, setError]);

    useEffect(() => {
        const codeFetch = new URLSearchParams(window.location.search);
        const code = codeFetch.get('code');
        const error = codeFetch.get('error_description');
        if (error) {
            setError(`External login server exception: ${error}`);
            return;
        }
        if (code) {
            onSso(code);
        } else {
            history.push('/login');
        }
    }, [history, onSso]);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                   Aquafresh Processing...
                </Typography>
                <br />
                {error
                    ? <Box>
                        <Box>
                            <Typography component="h1" variant="h6" style={{ color: 'red' }}>
                            Error: {error}
                            </Typography>
                        </Box>
                        <br />
                        <Box>
                            <Link to="/login">
                                Back to Login
                            </Link>
                        </Box>
                    </Box> : null
                }

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
