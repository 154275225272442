import React, { useState, createContext } from 'react';
import { sessionService } from '../sessionServices/sessionServices';
export const UserContext = createContext();

export const UserProvider = props => {
    const [user, setUser] = useState(sessionService.getUser() || null);

    return (
    // passing state and function that set state
        <UserContext.Provider value={[user, setUser]}>
            {props.children}
        </UserContext.Provider>
    );
};
