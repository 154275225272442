import axiosAPI from '../httpService/httpAxiosService';

export const textService = {
    addText,
    editText,
    deleteText,
    getAllText,
    getText
};

function addText(data) {
    return axiosAPI.post('/api/text', data);
}

function editText(id, data) {
    return axiosAPI.post(`/api/text/${id}`, data);
}

function deleteText(id) {
    return axiosAPI.delete(`/api/text/${id}`);
}

function getAllText(queryParams) {
    return axiosAPI.get(`/api/text${queryParams}`);
}

function getText(id) {
    return axiosAPI.get(`/api/text/${id}`);
}