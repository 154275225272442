import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { StylesProvider } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
// import BarChartIcon from "@material-ui/icons/BarChart";
// import LayersIcon from "@material-ui/icons/Layers";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SubjectIcon from '@material-ui/icons/Subject';
import AlbumIcon from '@material-ui/icons/Album';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PagesIcon from '@material-ui/icons/Pages';
import TranslateIcon from '@material-ui/icons/Translate';
// import StyleIcon from '@material-ui/icons/Style';
import { makeStyles } from '@material-ui/core/styles';
import './SideNav.scss';

const SideNavListItem = ({ isAdmin }) => {
    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper
        },
        nested: {
            paddingLeft: theme.spacing(4)
        }
    }));
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [openContents, setOpenContents] = React.useState(false);

    const handleOpenContent = () => {
        setOpenContents(!openContents);
    };

    const handleClickAdministration = () => {
        setOpen(!open);
    };
    return (
        <StylesProvider>
            <div className="nav-bar-list-wrapper">
                <NavLink
                    to="/dashboard"
                    exact
                    activeClassName="selected"
                    className="navbar-link"
                    style={{ textDecoration: 'none' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </NavLink>
                { isAdmin
                    ? <>
                        <ListItem button onClick={handleClickAdministration}>
                            <ListItemIcon>
                                <AssignmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Administration" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NavLink
                                    to="/dashboard/users"
                                    exact
                                    activeClassName="selected"
                                    className="navbar-link"
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Users" />
                                    </ListItem>
                                </NavLink>
                            </List>
                            <List component="div" disablePadding>
                                <NavLink
                                    to="/dashboard/languages"
                                    exact
                                    activeClassName="selected"
                                    className="navbar-link"
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemIcon>
                                            <TranslateIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Languages" />
                                    </ListItem>
                                </NavLink>
                            </List>
                        </Collapse>
                    </>
                    : null
                }

                <NavLink
                    to="/dashboard/pages"
                    exact
                    activeClassName="selected"
                    className="navbar-link"
                    style={{ textDecoration: 'none' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <PagesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pages" />
                    </ListItem>
                </NavLink>
                {/* <NavLink
                    to="/dashboard/css-management"
                    exact
                    activeClassName="selected"
                    className="navbar-link"
                    style={{ textDecoration: 'none' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <StyleIcon />
                        </ListItemIcon>
                        <ListItemText primary="CSS management" />
                    </ListItem>
                </NavLink> */}

                <ListItem button onClick={handleOpenContent}>
                    <ListItemIcon>
                        <SubjectIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contents" />
                    {openContents ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openContents} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/texts"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <TextFieldsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Texts" />
                            </ListItem>
                        </NavLink>
                    </List>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/dances"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <AlbumIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dances" />
                            </ListItem>
                        </NavLink>
                    </List>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/tracks"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <MusicNoteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Tracks" />
                            </ListItem>
                        </NavLink>
                    </List>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/characters"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Characters" />
                            </ListItem>
                        </NavLink>
                    </List>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/backgrounds"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <WallpaperIcon />
                                </ListItemIcon>
                                <ListItemText primary="Backgrounds" />
                            </ListItem>
                        </NavLink>
                    </List>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/props"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <DonutLargeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Props" />
                            </ListItem>
                        </NavLink>
                    </List>
                    <List component="div" disablePadding>
                        <NavLink
                            to="/dashboard/misc"
                            exact
                            activeClassName="selected"
                            className="navbar-link"
                        >
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <GroupWorkIcon />
                                </ListItemIcon>
                                <ListItemText primary="Misc" />
                            </ListItem>
                        </NavLink>
                    </List>
                </Collapse>
                <NavLink
                    to="/dashboard/files"
                    exact
                    activeClassName="selected"
                    className="navbar-link"
                    style={{ textDecoration: 'none' }}
                >
                    <ListItem button>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="File Management" />
                    </ListItem>
                </NavLink>
            </div>
        </StylesProvider>
    );
};

SideNavListItem.propTypes = {
    isAdmin: PropTypes.bool
};

export default SideNavListItem;
