import React, { useState, useEffect, useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FileItem from './FileItem.jsx';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FileModal from '../FileModal/FileModal';
import Alert from '../Alerts/Alerts.jsx';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import Loading from '../Loading/Loading.jsx';
import usePaginationHook from '../../customHooks/usePaginationHook';
import { mediaService } from '../../services/index';
import { FILE_CATEGORY } from '../../constants';
import './FileList.scss';

const ROWS_PER_PAGE = 10;

const FileList = () => {
    const [page, setPage] = React.useState(1);
    // const [dense, setDense] = React.useState(false);
    const [fileModalData, openFileModal] = useState({
        isOpen: false,
        asset: null
    });

    const countRows = useRef();

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const [searchTitle, setSearchTitle] = useState('');

    const [files, setFiles] = useState({ files: [] });

    const [error, setError] = useState('');

    const [selectedMediaCategory, setMediaCategory] = useState(0);

    const [success, setSuccess] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(files.count, ROWS_PER_PAGE);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(files.count);
    }, [handleNumberOfPages, files.count]);

    /**
   * getAllFiles
   * call assetService and get all backgrounds by the selected language
   */
    const getAllFiles = useCallback(async () => {
        try {
            let queryParams = `?page=${pageNumber}`;
            queryParams += `&pageSize=${ROWS_PER_PAGE}`;
            if (searchTitle) queryParams += `&title=${searchTitle}`;
            if (selectedMediaCategory) queryParams += `&mediaCategory=${selectedMediaCategory}`;
            const data = await mediaService.getAllMedia(queryParams);
            const newFiles = [...data.data.rows];
            if (countRows.current !== data.data.count) {
                setPage(1);
            }
            setFiles(prevState => {
                countRows.current = data.data.count;
                return {
                    ...prevState,
                    files: newFiles,
                    count: data.data.count
                };
            });
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [pageNumber, selectedMediaCategory, searchTitle]);

    useEffect(() => {
        getAllFiles();
    }, [pageNumber, getAllFiles, files.files.length, searchTitle, selectedMediaCategory]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    /**
   * addMedia
   * call mediaService and ad a new media object
   * @param {object} newData newData a media object that should be inserted
   */
    const addMedia = async (newData) => {
        try {
            if (!newData) {
                setError('All data are required.');
                return;
            }

            if (checkIsValid(newData)) {
                setIsLoading(true);
                const formData = new FormData();
                formData.append('preview', newData.cover);
                formData.append('file', newData.file);
                formData.append('title', newData.title);
                formData.append('mediaCategory', newData.mediaCategory || 1);
                formData.append('osSupport', newData.osSupport || 1);
                if (newData.osSupport === 2) formData.append('fileIOS', newData.fileIOS);
                const newBackground = await mediaService.addMedia(formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'multipart/form-data'
                    }
                });
                setIsLoading(false);
                setFiles(prevState => {
                    const prevData = [...prevState.files, newBackground.data];
                    return {
                        ...prevState,
                        files: prevData,
                        count: prevState.count + 1
                    };
                });
                setError('');
                openFileModal({});
                successAlertMsg('Successfully added new file');
            }
        } catch (err) {
            setIsLoading(false);
            openFileModal(null);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
     * handleCancel
     * close confirmation modal
     */
    const handleCancel = useCallback(() => {
        setOpenConfirmationModal(null);
    }, [setOpenConfirmationModal]);

    /**
     * deleteFile
     * call mediaService and delete a file
     * {data}
     */
    const deleteFile = useCallback(async (data) => {
        try {
            if (!data || !data.id) return;
            await mediaService.deleteMedia(data.id);

            getAllFiles();
            handleCancel();
            successAlertMsg(`Successfully deleted file: ${data.title}`);
        } catch (err) {
            openFileModal(null);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
            handleCancel();
        }
    }, [setError, getAllFiles, handleCancel]);

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.name === 'mediaCategory') setMediaCategory(e.target.value);
        if (e.target.name === 'title') setSearchTitle(e.target.value);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    /**
   * fileModal
   * open fileModal
   */
    const fileModal = useCallback((asset) => {
        if (!asset) {
            openFileModal(() => {
                return {
                    isOpen: true,
                    data: null,
                    title: 'Add new file',
                    coverPhoto: 'File cover photo',
                    coverFile: 'Upload file'
                };
            });
        } else {
            openFileModal(prevState => {
                return {
                    ...prevState,
                    isOpen: true,
                    data: asset,
                    title: 'Edit file',
                    coverPhoto: 'File cover photo',
                    coverFile: 'Upload file'
                };
            });
        }
    }, [openFileModal]);

    const checkIsValid = (data) => {
        if (!data) {
            setError('All data are required');
            return false;
        } else if (!data.cover) {
            setError('File cover photo is required');
            return false;
        } else if (!data.file) {
            setError('File is required');
            return false;
        } else if (!data.title) {
            setError('Missing title');
            return false;
        } else if (!data.osSupport) {
            setError('OS support is missing.');
            return false;
        } else if (!data.mediaCategory) {
            setError('Missing media category');
            return false;
        }
        return true;
    };

    const onDeleteFile = useCallback((data) => {
        setOpenConfirmationModal(() => {
            return {
                title: 'Delete media',
                message: `Are you sure you want delete ${data.title}`,
                isOpen: true,
                data: data,
                handleSubmit: () => deleteFile(data),
                handleClose: handleCancel
            };
        });
    }, [setOpenConfirmationModal, deleteFile, handleCancel]);

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    /**
   * updateMedia
   * call mediaService and update media
   * @param {object} data media (file) object that should be updated
   */
    const updateMedia = async (data) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('preview', data.cover);
            formData.append('file', data.file);
            formData.append('title', data.title);
            formData.append('mediaCategory', data.mediaCategory);
            formData.append('osSupport', data.osSupport);
            if (data.osSupport === 2) formData.append('fileIOS', data.fileIOS);
            await mediaService.editMedia(data.id, formData);
            setIsLoading(false);
            getAllFiles();
            openFileModal({});
            setError('');
            successAlertMsg(`Successfully updated file: ${data.title}`);
        } catch (err) {
            setIsLoading(false);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    const closeFileModal = () => {
        openFileModal({});
    };

    const handleSubmit = (data) => {
        if (fileModalData && fileModalData.data) {
            updateMedia(data);
        } else {
            addMedia(data);
        }
    };

    return (
        <div>
            {isLoading ? <Loading /> : null}
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            <FileModal {...fileModalData} onClose={closeFileModal} isLoading={isLoading} onSubmit={handleSubmit} />
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null}
            <Grid container>
                <Grid container justify="space-between">
                    <Paper component="form" className="paper-wrapper">
                        <Grid container justify="space-between">
                            <Grid>
                                <InputBase
                                    placeholder="Search"
                                    onChange={handleChange}
                                    name="title"
                                />
                                <IconButton type="submit" aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                <Select
                                    style={{ padding: '0 1rem', marginLeft: '1rem' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedMediaCategory || 0}
                                    onChange={handleChange}
                                    name="mediaCategory"
                                    // fullWidth
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={FILE_CATEGORY.VIDEO.value}>{FILE_CATEGORY.VIDEO.name}</MenuItem>
                                    <MenuItem value={FILE_CATEGORY.TRACK.value}>{FILE_CATEGORY.TRACK.name}</MenuItem>
                                    <MenuItem value={FILE_CATEGORY.BACKGROUND.value}>{FILE_CATEGORY.BACKGROUND.name}</MenuItem>
                                    <MenuItem value={FILE_CATEGORY.BUNDLE.value}>{FILE_CATEGORY.BUNDLE.name}</MenuItem>
                                    <MenuItem value={FILE_CATEGORY.OTHER.value}>{FILE_CATEGORY.OTHER.name}</MenuItem>
                                </Select>
                            </Grid>
                            <Grid className="btn-wrapper">
                                <Button className="add-btn" size="small" variant="outlined" color="default" onClick={() => fileModal()}>Add</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                {
                    files.files.map(item => {
                        return (
                            <Grid key={item.id} item md={4} lg={2} style={{ marginTop: '2rem', marginLeft: '1.5rem', marginRight: '1.5rem' }}>
                                <FileItem key={item.id} data={item} onEdit={fileModal} onDelete={onDeleteFile} />
                            </Grid>
                        );
                    })
                }
                <Grid container justify="flex-end">
                    {numberOfPages > 0
                        ? <Pagination
                            style={{ padding: '0 1rem 1rem 0' }}
                            className="table-pagination"
                            count={numberOfPages}
                            page={page}
                            onChange={handleChangePage}
                        />
                        : null }
                </Grid>
            </Grid>
        </div>
    );
};

export default FileList;
