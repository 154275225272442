import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import Alert from '../Alerts/Alerts.jsx';
import CreateUserModal from '../CreateUserModal/CreateUserModal.jsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import Pagination from '@material-ui/lab/Pagination';
import { userService } from '../../services/index';
import './UsersTable.scss';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    }
});

export default function UsersTable() {
    const [page, setPage] = React.useState(1);
    // const [sort, setSort] = useState({id: 'asc'})
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const [error, setError] = useState('');
    const [userModalData, openUserModal] = useState({
        isOpen: false,
        user: null
    });

    const [success, setSuccess] = useState(null);

    const [users, setUsers] = useState({ users: [] });
    const { sort, handleSort } = useSortHook('id');
    const { numberOfPages, handleNumberOfPages } = usePaginationHook(users.count);

    const [pageNumber, setPageNumber] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        handleNumberOfPages(users.count);
    }, [handleNumberOfPages, users.count]);
    /**
   * getAllUsers
   * call users service to get all users by the quey
   */
    const getAllUsers = useCallback(async () => {
        try {
            if (sort) {
                const queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                const data = await userService.getAllUsers(queryParams);
                const newUsers = [...data.data.rows];
                setUsers(prevState => {
                    return {
                        ...prevState,
                        users: newUsers,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [setUsers, pageNumber, sort]);

    /**
   * Call use effect to fetch all users
   */
    useEffect(() => {
        getAllUsers();
        return () => {
        };
    }, [sort, page, getAllUsers, users.users.length]);

    /**
   * addUserModal
   * open add user modal
   */
    const addUserModal = () => {
        openUserModal(() => {
            return {
                isOpen: true,
                user: null
            };
        });
    };

    /**
   * editUserModal
   * open edit user modal
   * @param {object} user user object for edit
   */
    const editUserModal = (user) => {
        try {
            openUserModal(() => {
                return {
                    isOpen: true,
                    user: user
                };
            });
        } catch (error) {
            console.log(error);
        }
    };

    /**
   * addUserAction
   * call userService and add a new user
   * @param {object} user user that should be inserted
   */
    const addUserAction = async (user) => {
        try {
            const data = await userService.addUser(user);
            setUsers(prevState => {
                const newUsers = [...prevState.users, data.data];
                return {
                    ...prevState,
                    users: newUsers,
                    count: prevState.count + 1
                };
            });
            setError('');
            openUserModal({});
            successAlertMsg(`Successfully created user ${user.name}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * deleteUser
   * call userService and delete user
   * @param {Object} user
   */
    const deleteUser = async (user) => {
        try {
            await userService.deleteUser(user.id);
            setUsers(prevState => {
                return {
                    users: [...prevState.users.filter(item => item.id !== user.id)],
                    count: prevState.count - 1
                };
            });
            setOpenConfirmationModal(false);
            setError('');
            successAlertMsg(`Successfully deleted user ${user.name}`);
        } catch (err) {
            setOpenConfirmationModal(false);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * updateUser
   * call userService and update user
   * @param {object} user
   */
    const updateUser = async (user) => {
        if (user && user.lvl === 1 && user.active === 0) {
            setError('Can not set admin user as inactive.');
            return;
        }
        try {
            const updatedUser = await userService.editUser(user._id, user);
            setUsers(prevState => {
                const prevUsers = [...prevState.users];
                return {
                    ...prevState,
                    users: prevUsers.map(item => item.id === user.id ? updatedUser.data : item)
                };
            });
            openUserModal({});
            successAlertMsg(`Successfully updated user: ${user.name}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    const closeAddUserModal = () => {
        openUserModal({});
    };

    /**
   * handleSubmit
   * call add/edit user method
   * @param {object} user
   */
    const handleSubmit = (user) => {
        if (userModalData && userModalData.user) {
            updateUser(user);
        } else {
            addUserAction(user);
        }
    };

    /**
   *
   * @param {*} event
   * @param {*} newPage
   */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    const onDeleteUser = (data) => {
        if (data && data.lvl === 1) {
            setError('Can not delete admin user.');
            return;
        }
        setOpenConfirmationModal(() => {
            return {
                title: 'Delete user',
                message: `Are you sure you want delete ${data.name}`,
                isOpen: true,
                data: data,
                handleSubmit: deleteUser,
                handleClose: handleCancel
            };
        });
    };

    const handleCancel = () => {
        setOpenConfirmationModal(null);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen} handleClose={closeAlertMessage} /> : null}
            <CreateUserModal {...userModalData} onClose={closeAddUserModal} onSubmit={handleSubmit} />
            <Button className="add-user-btn" size="small" variant="outlined" color="default" onClick={addUserModal}>Add</Button>
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal} /> : null}
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => { handleSort('id'); handleChangePage(null, 1); }}>ID</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('name'); handleChangePage(null, 1); }} align="center">Name</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('username'); handleChangePage(null, 1); }} align="center">Username</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('email'); handleChangePage(null, 1); }} align="center">Email</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('lvl'); handleChangePage(null, 1); }} align="center">Role</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active'); handleChangePage(null, 1); }} align="right">Active</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('modifiedBy'); handleChangePage(null, 1); }} align="right">Modified By</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('updatedAt'); handleChangePage(null, 1); }} align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.users.map(row => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">{row.username}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.lvl === 1 ? 'Admin' : 'User'}</TableCell>
                            <TableCell align="right">{row.active === 1 ? 'Active' : 'Inactive'}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => editUserModal(row)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                <Button size="small" onClick={() => onDeleteUser(row)} className={classes.margin}>
                                    <DeleteOutlineIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
