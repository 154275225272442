import axiosAPI from '../httpService/httpAxiosService';

export const userService = {
    login,
    logout,
    addUser,
    editUser,
    deleteUser,
    getAllUsers,
    getUser,
    forgotPassword,
    changePassword,
    ssoLogin
};

function login(user) {
    return axiosAPI.post('/api/user/login', user);
}

function ssoLogin(code) {
    return axiosAPI.post(`/api/user/login?code=${code}`);
}

function logout() {
    return axiosAPI.post('/api/user/logout', {});
}

function addUser(data) {
    return axiosAPI.post('/api/user', data);
}

function editUser(userId, data) {
    return axiosAPI.post(`/api/user/${userId}`, data);
}

function deleteUser(userId) {
    return axiosAPI.delete(`/api/user/${userId}`);
}

function getAllUsers(queryParams = '') {
    // return axiosAPI.get(`/api/user?page=${page}&order_field=${order_field}&order=${order}`)
    return axiosAPI.get(`/api/user${queryParams}`);
}

function getUser(userId, cancelToken) {
    return axiosAPI.get(`/api/user/${userId}`, cancelToken);
}

function forgotPassword(email) {
    return axiosAPI.post('/api/user/forgot/password', email);
}

function changePassword(data) {
    return axiosAPI.post('/api/user/reset/password', data);
}

