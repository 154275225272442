import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PrivateLayout from './layouts/privateLayout.jsx';
import Login from './pages/Login/Login.jsx';
import Dashboard from './pages/Dashboard/Dashboard.jsx';
// import ForgotPassword from './pages/ForgotPassword/ForgotPassword.jsx';
import Users from './components/Users/Users.jsx';
import LanguagesCmp from './components/LanguagesCmp/LanguagesCmp.jsx';
import Texts from './components/Texts/Texts.jsx';
import { sessionService } from './sessionServices/sessionServices';

import PageList from './pages/PageList/PageList.jsx';
import AddNewPage from './pages/AddNewPage/AddNewPage.jsx';
import EditPage from './pages/EditPage/EditPage.jsx';
import { UserContext } from './contexts/userContext.jsx';
import BackgroundPage from './pages/BackgroundPage/BackgroundPage.jsx';
import DancePage from './pages/DancePage/DancePage.jsx';
import CharacterPage from './pages/CharacterPage/CharacterPage.jsx';
import TrackPage from './pages/TrackPage/TrackPage.jsx';
import FileManagement from './pages/FileManagementPage/FileManagementPage.jsx';
import PropsPage from './pages/PropsPage/PropsPage.jsx';
import SingleSignOn from './pages/LoginSingleSignOn/LoginSingleSignOn.jsx';
import SSOHandler from './pages/LoginSingleSignOn/SSOHandler.jsx';
import MiscPage from './pages/MiscPage/MiscPage.jsx';
// import CssPage from './pages/CssPage/CssPage.jsx';
// import CssPageAdd from './pages/CssPageAdd/CssPageAdd.jsx';
// import CssPageEdit from './pages/CssPageEdit/CssPageEdit.jsx';

import config from './config';
import PagePreview from './components/AddNewPageEditor/PagePreview.jsx';

const { GSK_AUTH_URL } = config;

const Routes = () => {
    const [user] = useContext(UserContext);
    const [, setCurrentUser] = useState(null);

    useEffect(() => {
        setCurrentUser(user);
    }, [user]);

    return (
        <div>
            <Switch>
                { GSK_AUTH_URL
                    ? <Route exact path='/login' component={SingleSignOn} />
                    : <Route exact path='/login' component={Login} />
                }
                { GSK_AUTH_URL ? <Route exact path="/" component={SSOHandler}/> : null}
                {/* <Route exact path='/forgot-password' component={ForgotPassword} /> */}
                {/* <PrivateLayout exact path="/change-password" component={ChangePassword} /> */}
                <PrivateLayout exact path="/dashboard" component={Dashboard} />
                { sessionService.isAdmin() ? <PrivateLayout exact path="/dashboard/users" component={Users} /> : null }
                { sessionService.isAdmin() ? <PrivateLayout exact path="/dashboard/languages" component={LanguagesCmp} /> : null }
                <PrivateLayout exact path="/dashboard/texts" component={Texts} />
                <PrivateLayout exact path="/dashboard/pages" component={PageList} />
                { sessionService.isAuth() ? <Route exact path='/dashboard/page-preview' component={PagePreview} /> : null }
                {/* <PrivateLayout exact path="/dashboard/css-management" component={CssPage} />
                <PrivateLayout exact path="/dashboard/css/add" component={CssPageAdd} />
                <PrivateLayout exact path="/dashboard/css/edit/:id" component={CssPageEdit} /> */}
                <PrivateLayout exact path="/dashboard/pages/add" component={AddNewPage} />
                <PrivateLayout exact path="/dashboard/pages/edit/:id" component={EditPage} />
                <PrivateLayout exact path="/dashboard/backgrounds" component={BackgroundPage} />
                <PrivateLayout exact path="/dashboard/dances" component={DancePage} />
                <PrivateLayout exact path="/dashboard/characters" component={CharacterPage} />
                <PrivateLayout exact path="/dashboard/tracks" component={TrackPage} />
                <PrivateLayout exact path="/dashboard/props" component={PropsPage} />
                <PrivateLayout path="/dashboard/files" component={FileManagement} />
                <PrivateLayout exact path="/dashboard/misc" component={MiscPage} />
                <Route render={() => <Redirect to="/dashboard" />} />
            </Switch>
        </div>
    );
};

export default Routes;
