import axiosAPI from '../httpService/httpAxiosService';

export const cssService = {
    addCSS,
    editCSS,
    deleteCSS,
    getAllCSS,
    getCSS
};

function addCSS(data) {
    return axiosAPI.post('/api/css', data);
}

function editCSS(id, data) {
    return axiosAPI.post(`/api/css/${id}`, data);
}

function deleteCSS(id) {
    return axiosAPI.delete(`/api/css/${id}`);
}

function getAllCSS(queryParams) {
    return axiosAPI.get(`/api/css${queryParams}`);
}

function getCSS(id, cancelToken) {
    return axiosAPI.get(`/api/css/${id}`, cancelToken);
}