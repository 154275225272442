import axiosAPI from '../httpService/httpAxiosService';

export const languageService = {
    addLanguage,
    editLanguage,
    deleteLanguage,
    getAllLanguages,
    getLanguage,
    getAllLanguagesWithoutParams,
    getAllLanguagesIncludingInactive
};

function addLanguage(data) {
    return axiosAPI.post('/api/language', data);
}

function editLanguage(acronym, data) {
    return axiosAPI.post(`/api/language/${acronym}`, data);
}

function deleteLanguage(id) {
    return axiosAPI.delete(`/api/language/${id}`);
}

function getAllLanguages(queryParams) {
    return axiosAPI.get(`/api/language${queryParams}`);
}

function getAllLanguagesWithoutParams() {
    return axiosAPI.get('/api/language/all');
}

function getAllLanguagesIncludingInactive() {
    return axiosAPI.get('/api/language/all/includingInactive');
}

function getLanguage(id) {
    return axiosAPI.get(`/api/language/${id}`);
}
