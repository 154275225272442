import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '../Alerts/Alerts.jsx';
import { userService } from '../../services/index';

import config from '../../config';

const { API_BASE_URL } = config;

const useStyles = makeStyles(theme => ({
    actions: {
        display: 'flex'
    },
    card: {
        maxWidth: 500,
        marginBottom: '1rem'
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    avatar: {
        backgroundColor: red[500]
    }
}));

const FileItem = ({ data, onEdit, onDelete }) => {
    const classes = useStyles();
    const [anchorEl, setIsOpen] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);
    const [error, setError] = useState('');

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
    };

    /**
     * handleClick
     * open a three dots modal
     * @param {event} event an event
     */
    const handleClick = event => {
        setIsOpen(event.currentTarget);
    };

    /**
     * close a three dots menu
     */
    const handleClose = () => {
        setIsOpen(null);
    };

    /**
     * getUser
     * call userService and get a user who was a modified data
     */
    const getUser = useCallback(async (cancelToken) => {
        try {
            const res = await userService.getUser(data.modifiedBy, cancelToken);
            if (res && res.data && res.data) {
                setCreatedBy(res.data);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [data.modifiedBy]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        getUser({ cancelToken: source.token });
        return () => {
            source.cancel();
        };
    }, [getUser]);

    /**
     * onEditClick
     * open edit modal
     */
    const onEditClick = async () => {
        if (onEdit) {
            onEdit({ data });
            setIsOpen(null);
        }
    };

    const onDeleteClick = () => {
        if (onDelete) {
            onDelete(data);
            setIsOpen(null);
        }
    };

    const download = (data, os) => {
        const elem = window.document.createElement('a');
        if (os && os === 'ios') {
            elem.setAttribute('href', `${API_BASE_URL}/api/media/file/${data.id}?ios=true`);
        } else {
            elem.setAttribute('href', `${API_BASE_URL}/api/media/file/${data.id}`);
        }
        elem.setAttribute('download', data.fileName);
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    };

    const onDownloadClick = async (os) => {
        setIsOpen(null);
        if (data && data.id) {
            download(data, os);
        }
    };

    return (
        <>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}

            <Card className={classes.card}>
                <CardHeader
                    action={
                        <IconButton aria-label="settings" onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    title={data.title}
                />
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >

                    <MenuItem onClick={onEditClick} title="Edit"><EditIcon /> Edit</MenuItem>
                    <MenuItem onClick={() => onDownloadClick()} title={`Download: ${data && data.fileName ? data.fileName : ''}`}><GetAppIcon />Download {data && data.fileIOSName ? 'Android' : ''}</MenuItem>
                    {data && data.fileIOSName ? <MenuItem onClick={() => onDownloadClick('ios')} title={`Download: ${data && data.fileIOSName ? data.fileIOSName : ''}`}><GetAppIcon />Download ios</MenuItem> : null}
                    <MenuItem onClick={onDeleteClick} title="Delete"><DeleteOutlineIcon />Delete</MenuItem>
                </Menu>
                <CardMedia
                    className={classes.media}
                    title={data.fileName}
                    image={`${API_BASE_URL}/api/media/preview/${data.id}`}
                    key={data.updatedAt}
                />
                <CardContent>
                    <Typography variant="caption" color="textSecondary" component="p">
                        <strong>Modified by: </strong>
                        {`${createdBy && createdBy.name ? createdBy.name : ''}, ${createdBy && createdBy.username ? createdBy.username : ''}`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="p">
                        <strong>Modified at: </strong>
                        {data && data.updatedAt ? moment(data.updatedAt).format('DD.MM.YYYY, HH:mm') : ''}
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
};

FileItem.propTypes = {
    data: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default React.memo(FileItem);
