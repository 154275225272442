import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Loading from '../Loading/Loading';
import { FILE_CATEGORY } from '../../constants';
import config from '../../config';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

const FileModal = ({
    onClose,
    isOpen = false,
    data,
    onSubmit,
    title,
    coverPhoto,
    coverFile,
    isTrack,
    isLoading
}) => {
    const [assetData, setAssetData] = useState({
        title: '',
        cover: null,
        file: null,
        fileName: '',
        mediaCategory: '',
        osSupport: '',
        // preview: '',
        id: '',
        fileIOS: null,
        fileIOSName: ''
    });

    const classes = useStyles();

    useEffect(() => {
        if (data && data.data) {
            setAssetData(prevState => {
                return {
                    ...prevState,
                    title: data.data.title,
                    osSupport: data.data.osSupport,
                    mediaCategory: data.data.mediaCategory,
                    fileName: data.data.fileName,
                    // preview: data.preview.data.preview,
                    id: data.data.id,
                    fileIOSName: data.data.fileIOSName
                };
            });
        }
        return () => {
            setAssetData({});
        };
    }, [data]);

    /**
     * handleChange event on change
     * @param {e} e an event
     */
    const handleChange = e => {
        e.persist();
        if (e.target.name === 'cover' || e.target.name === 'file' || e.target.name === 'fileIOS') {
            if (e.target.files[0]) {
                setAssetData(prevSate => {
                    const newState = { ...prevSate };
                    return {
                        ...newState,
                        [e.target.name]: e.target.files[0],
                        [e.target.name + 'Img']: URL.createObjectURL(e.target.files[0])
                    };
                });
            }
        } else {
            setAssetData(prevSate => {
                const newState = { ...prevSate };
                return {
                    ...newState,
                    [e.target.name]: e.target.value
                };
            });
        }
    };

    /**
     * handle submit
     * @param {e} e an event
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isLoading) return false;
        onSubmit(assetData);
    };

    const renderMediaCategory = () => {
        return (
            <FormControl className={classes.formControl}>

                <InputLabel id="demo-simple-select-label">Select category</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={assetData.mediaCategory || ''}
                    onChange={handleChange}
                    name="mediaCategory"
                    fullWidth
                >

                    {
                        Object.keys(FILE_CATEGORY).map(item => {
                            return (
                                <MenuItem key={item} value={FILE_CATEGORY[item].value}>
                                    {FILE_CATEGORY[item].name}
                                </MenuItem>
                            );
                        })
                    }

                </Select>
            </FormControl>
        );
    };

    if (isLoading) return <Loading />;

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">{title || ''}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="title"
                            label="Title"
                            type="text"
                            name="title"
                            value={assetData && assetData.title ? assetData.title : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <br />
                        <br />
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Support OS</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={assetData && assetData.osSupport ? assetData.osSupport : ''}
                                onChange={handleChange}
                                name="osSupport"
                            >
                                <MenuItem value={1}>Both</MenuItem>
                                <MenuItem value={2}>Separate</MenuItem>
                            </Select>
                        </FormControl>
                        {renderMediaCategory()}
                        <br />
                        <br />
                        {isTrack ? null : (
                            <Button variant="contained" component="label">
                                <Avatar
                                    alt="Aquafresh cover photo"
                                    src={
                                        assetData && assetData.coverImg
                                            ? assetData.coverImg
                                            : assetData && assetData.image
                                                ? config.API_BASE_URL + '/' + assetData.image
                                                // : assetData && assetData.preview ? `data:image/png;base64,${assetData.preview}` : ''
                                                : assetData && assetData.id ? `${config.API_BASE_URL}/api/media/preview/${assetData.id}` : ''
                                    }
                                    style={{ marginRight: '.5rem' }}
                                />{' '}
                                {coverPhoto || ''}
                                {assetData && assetData.cover && assetData.cover.name ? (
                                    <>
                  : <small>{assetData && assetData.cover && assetData.cover.name ? assetData.cover.name : ''} </small>
                                    </>
                                ) : assetData && assetData.imageName ? (
                                    <>
                                        {': '}
                                        <small>{assetData.imageName}</small>
                                    </>
                                ) : (
                                    ''
                                )}
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleChange}
                                    name="cover"
                                />
                            </Button>
                        )}
                        <br />
                        <br />
                        <Button variant="contained" component="label">
                            {coverFile || ''}
                            {assetData && assetData.cover && assetData.cover.name ? (
                                <>
                : <small>{assetData && assetData.file && assetData.file.name ? assetData.file.name : ''} </small>
                                </>
                            ) : assetData && assetData.fileName ? (
                                <>
                                    {': '}
                                    <small>{assetData.fileName}</small>
                                </>
                            ) : (
                                ''
                            )}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleChange}
                                name="file"
                            />
                        </Button>
                        <br />
                        {
                            assetData.osSupport === 2
                                ? <Button variant="contained" component="label" style={{ marginTop: '1rem' }}>
                                    {coverFile + ' IOS' || ''}
                                    {assetData && assetData.fileIOS && assetData.fileIOS.name ? (
                                        <>
                : <small>{assetData && assetData.fileIOS && assetData.fileIOS.name ? assetData.fileIOS.name : ''} </small>
                                        </>
                                    ) : assetData && assetData.fileIOSName ? (
                                        <>
                                            {': '}
                                            <small>{assetData.fileIOSName}</small>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleChange}
                                        name="fileIOS"
                                    />
                                </Button> : null
                        }

                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
            Cancel
                        </Button>
                        <Button type="submit" color="primary">
            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

FileModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    coverPhoto: PropTypes.string,
    coverFile: PropTypes.string,
    isTrack: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default FileModal;
