import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { StylesProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import FileList from '../../components/FileList/FileList.jsx';

const FileManagement = () => {
    const useStyles = makeStyles(theme => ({
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidthLg: "100%"
        },
        paper: {
            padding: theme.spacing(2),
            display: "flex",
            overflow: "auto",
            flexDirection: "column"
        },
        fixedHeight: {
            height: 240
        },
        wrapper: {
            width: "100% !important"
        }
    }));
    const classes = useStyles();

    return (
        <StylesProvider>
            <main className="users-wrapper">
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container>
                        <Typography variant="h5" gutterBottom>
              File Management
                        </Typography>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper>
                                <FileList />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </StylesProvider>
    );
};

export default FileManagement;