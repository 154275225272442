import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './PagePreview.scss';
import config from '../../config';

const { API_BASE_URL } = config;

const PagePreview = (props) => {
    let __html = sessionStorage.getItem('htmlPage') || ''
    __html = __html.replace(/\/api\/media\/file/gi, API_BASE_URL+"/api/media/file");
    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html }} />
        </React.Fragment>
    );
};
PagePreview.propTypes = {
    props: PropTypes.object
};
export default withRouter(PagePreview);
