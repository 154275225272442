export const sessionService = {
    /**
   * Stores user json object ot local storage
   * @param user
   */
    create(user) {
        localStorage.setItem('userAQF', JSON.stringify(user));
    },
    /**
   * Destroy user object from local storage and redirects to login
   */
    destroy() {
        localStorage.removeItem('userAQF');
    },

    getUserId() {
        const data = localStorage.getItem('userAQF');
        if (!data) return null;
        const user = JSON.parse(data);
        return (user) ? user.user._id : '';
    },

    getUser() {
        const data = localStorage.getItem('userAQF');
        if (!data) return null;
        const user = JSON.parse(data);
        return (user) ? user.user : '';
    },
    getSessionToken() {
        const data = localStorage.getItem('userAQF');
        if (!data) return null;
        const session = JSON.parse(data);
        return (session && session.token) ? session.token : null;
    },

    isAdmin() {
        const data = localStorage.getItem('userAQF');
        if (!data) return false;
        const { user } = JSON.parse(data);
        return !!(user && user.lvl && user.lvl === 1);
    },

    isAuth() {
        const data = localStorage.getItem('userAQF');
        if (!data) {
            return false;
        } else {
            const session = JSON.parse(data);
            return session.token;
        }
    }
};
