import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Alert from '../Alerts/Alerts.jsx';
import { userService } from '../../services/index';

const CSSItem = ({ data, onClick }) => {
    const [createdBy, setCreatedBy] = useState(null);
    const [error, setError] = useState('');

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
    };

    /**
     * getUser
     * call userService and get a user who was a modified data
     */
    const getUser = useCallback(async (cancelToken) => {
        try {
            const res = await userService.getUser(data.modifiedBy, cancelToken);
            if (res && res.data && res.data) {
                setCreatedBy(res.data);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [data.modifiedBy]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        getUser({ cancelToken: source.token });
        return () => {
            source.cancel();
        };
    }, [getUser]);

    const handleClickOnCard = () => {
        if (onClick) {
            onClick(data);
        }
    };

    return (
        <>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}

            <div onClick={handleClickOnCard} createdBy={createdBy}>{data.title}</div>
        </>
    );
};

CSSItem.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func
};

export default React.memo(CSSItem);
