import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from '../Alerts/Alerts.jsx';
import { MISC_TYPE } from '../../constants';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
    }
});

const MISC_TYPE_MAP = {}
MISC_TYPE.forEach(type => MISC_TYPE_MAP[type.value] = type.name)

const PageAssetsTable = ({ assets = [], onDelete }) => {
    const [copied, setCopied] = useState(false);

    const classes = useStyles();

    const closeAlertMessage = () => {
        setCopied(false);
    };

    return (
        <React.Fragment>
            {copied ? <Alert type="success" message="URL has been copied" open={ copied } handleClose={ closeAlertMessage } /> : null}
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th">ID</TableCell>
                        <TableCell className="users-table-th" align="right">Title</TableCell>
                        <TableCell className="users-table-th" align="right">Type</TableCell>
                        <TableCell className="users-table-th" align="right">File Path</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assets.map(asset => (
                        <TableRow key={asset.id}>
                            <TableCell>{asset.id}</TableCell>
                            <TableCell align="right">{asset.title}</TableCell>
                            <TableCell align="right">{MISC_TYPE_MAP[asset.type]}</TableCell>
                            <TableCell align="right">
                                {`/api/media/file/${asset.mediaId}`}
                                <CopyToClipboard
                                    onCopy={() => setCopied(true)}
                                    text={`/api/media/file/${asset.mediaId}`}
                                    message="copy file">
                                    <FileCopyOutlined/>
                                </CopyToClipboard>
                            </TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => onDelete(asset)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
};

PageAssetsTable.propTypes = {
    assets: PropTypes.array,
    onDelete: PropTypes.func
};
export default PageAssetsTable;