import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './routes.jsx';
import { UserProvider } from './contexts/userContext.jsx';
import { LanguageProvider } from './contexts/languagesContext.jsx';

function App() {
    return (
        <div className="App">
            <UserProvider>
                <LanguageProvider>
                    <Router>
                        <Routes />
                    </Router>
                </LanguageProvider>
            </UserProvider>
        </div>
    );
}

export default App;
