import React, { useEffect, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import Alert from '../Alerts/Alerts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import { assetService } from '../../services/index';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import AssetModal from '../AssetModal/AssetModal.jsx';
import { ASSET_CATEGORIES, DEFAULT_ROWS_PER_PAGE } from '../../constants';
import config from '../../config';
import { isNumber } from '../../utils/number.utils';
import './PropsTable.scss';

const useStyles = makeStyles({
    table: {
    // minWidth: 650,
    }
});

export default function PropsTable() {
    const [page, setPage] = React.useState(1);
    // const [dense, setDense] = React.useState(false);
    const { sort, handleSort } = useSortHook('title');
    const [dataProps, setDataProps] = useState({ dataProps: [] });

    const [propsModalData, openPropsModalData] = useState({
        isOpen: false,
        dataProps: null
    });

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [success, setSuccess] = useState(null);
    const [characters, setCharacters] = useState([]);

    const { selectedLng } = useContext(
        LanguageContext
    );

    const [error, setError] = useState('');
    const classes = useStyles();

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(dataProps.count);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(dataProps.count);
    }, [handleNumberOfPages, dataProps.count]);

    /**
   * getAllProps
   * call assetService and get all props by the selected language
   */

    const getAllProps = useCallback(async () => {
        try {
            if (sort) {
                const lng = selectedLng && selectedLng.id ? selectedLng.id : '';
                let queryParams;
                if (lng) {
                    queryParams =
                    `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&category=${ASSET_CATEGORIES.PROP_BODY}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}&langId=${lng}`;
                } else {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&category=${ASSET_CATEGORIES.PROP_HEAD}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                }
                const data = await assetService.getAllAssets(queryParams);
                const newProps = [...data.data.rows];
                setDataProps(prevState => {
                    return {
                        ...prevState,
                        dataProps: newProps,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [pageNumber, sort, selectedLng, setDataProps]);

    const getAllCharacters = useCallback(async () => {
        try {
            const acronym = selectedLng && selectedLng.acronym ? selectedLng.acronym : '';
            if (acronym) {
                const params = `?active=-1&filter_field[]=lang&filter_value[]=${acronym}&filter_field[]=type&filter_value[]=${ASSET_CATEGORIES.CHARACTER}`;
                const characters = await assetService.getAll(params);
                setCharacters(characters.data);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [setCharacters, setError, selectedLng]);

    useEffect(() => {
        getAllProps();
        return () => {
        };
    }, [sort, pageNumber, getAllProps, dataProps.dataProps.length]);

    useEffect(() => {
        getAllCharacters();
    }, [getAllCharacters]);

    const checkIsValid = (data) => {
        if (!data) {
            setError('All data are required');
            return false;
        } else if (!data.title) {
            setError('Missing title');
            return false;
        } else if (!isNumber(data.brushesNeeded)) {
            setError('Counter missing');
            return false;
        } else if (!(data.active > -1) && !(data.active < 2)) {
            setError('Active is not set');
            return false;
        } else if (!data.mediaId) {
            setError('Media not selected');
            return false;
        }
        return true;
    };

    /**
   * addProp
   * call assetService and ad a new prop object
   * @param {object} newData newData a prop object that should be inserted
   */

    const addProps = useCallback(async (newData, characters, type) => {
        try {
            if (!newData) {
                setError('All data are required.');
                return;
            }
            if (!selectedLng || !selectedLng.id) {
                setError('Please select a language');
                return;
            }
            if (!type) {
                setError('Please select a prop type');
                return;
            }
            if (!characters || characters.length === 0) {
                setError('Please select a character');
                return;
            }
            newData.langId = selectedLng.id;
            newData.category = type;
            newData.type = type;
            newData.characters = characters;
            if (checkIsValid(newData)) {
                newData.brushesNeeded = newData.brushesNeeded.toString();
                const newProps = await assetService.addAsset(newData);
                setDataProps(prevState => {
                    const prevData = [...prevState.dataProps, newProps.data];
                    return {
                        ...prevState,
                        dataProps: prevData,
                        count: prevState.count + 1
                    };
                });
                setError('');
                openPropsModalData({});
                getAllProps();
                successAlertMsg('Successfully added new prop');
            }
        } catch (err) {
            openPropsModalData(null);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [getAllProps, selectedLng]);

    /**
   * deleteProps
   * call assetService and delete a prop
   * @param {object} page prop object that should be deleted
   */

    const deleteProps = async (dataProps) => {
        try {
            await assetService.deleteAsset(dataProps.id);
            setDataProps(prevState => {
                const propsList = [...prevState.dataProps.filter(item => item.id !== dataProps.id)];
                if (propsList.length === 0 && page > 0) handleChangePage(null, page - 1);
                return {
                    dataProps: propsList,
                    count: prevState.count - 1
                };
            });
            setError('');
            openPropsModalData({});
            handleCancel();
            getAllProps();
            successAlertMsg(`Successfully deleted prop: ${dataProps.title}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
            handleCancel();
        }
    };

    const onDeleteProps = (data) => {
        setOpenConfirmationModal(() => {
            return {
                title: 'Delete prop',
                message: `Are you sure you want delete ${data.title}`,
                isOpen: true,
                data: data,
                handleSubmit: deleteProps,
                handleClose: handleCancel
            };
        });
    };

    const handleCancel = () => {
        setOpenConfirmationModal(null);
    };

    /**
   * updateProp
   * call assetService and update prop
   * @param {object} data prop object that should be updated
   */

    const updateProps = useCallback(async (data, characters, type) => {
        try {
            if (!data) {
                setError('All data are required.');
                return;
            }
            if (!selectedLng || !selectedLng.id) {
                setError('Please select a language');
                return;
            }
            if (!type) {
                setError('Please select a prop type');
                return;
            }
            if (!characters || characters.length === 0) {
                setError('Please select a character');
                return;
            }
            data.langId = selectedLng.id;
            data.category = type;
            data.type = type;
            data.characters = characters;
            data.brushesNeeded = data.brushesNeeded.toString();
            await assetService.editAsset(data.id, data);
            // const updatedProps = await assetService.editAsset(data.id, data);
            // TODO: missing characters in updatedProps;
            // setDataProps(prevState => {
            //     const prevProps = [...prevState.dataProps];
            //     return {
            //         ...prevState,
            //         dataProps: prevProps.map(item => item.id === data.id ? updatedProps.data : item)
            //     };
            // });
            openPropsModalData({});
            setError('');
            getAllProps();
            successAlertMsg(`Successfully updated prop: ${data.title}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [selectedLng, getAllProps]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    const closePropsModal = () => {
        openPropsModalData({});
    };

    const handleSubmit = useCallback((data, characters, type) => {
        if (propsModalData && propsModalData.data) {
            updateProps(data, characters, type);
        } else {
            addProps(data, characters, type);
        }
    }, [updateProps, addProps, propsModalData]);

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    /**
   * propsModal
   * open propsModal
   */

    const propsModal = (dataProps) => {
        if (!selectedLng || !selectedLng.id) {
            setError('Please select a language.');
            return;
        }
        if (!dataProps) {
            openPropsModalData(() => {
                return {
                    isOpen: true,
                    data: null,
                    title: 'Add new prop',
                    coverPhoto: 'Prop cover photo',
                    coverFile: 'Prop file'
                };
            });
        } else {
            openPropsModalData(prevState => {
                return {
                    ...prevState,
                    isOpen: true,
                    data: dataProps,
                    title: 'Edit prop',
                    coverPhoto: 'Prop cover photo',
                    coverFile: 'Prop file'
                };
            });
        }
    };

    const getType = (type) => {
        if (type === 6) {
            return 'Head';
        } else if (type === 8) {
            return 'Body';
        } else if (type === 9) {
            return 'Feet';
        }
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null}
            <Button className="add-text-btn" size="small" variant="outlined" color="default" onClick={() => propsModal()}>Add</Button>
            { propsModalData && propsModalData.isOpen ? <AssetModal
                {...propsModalData} isProp={true} characters={characters} onClose={closePropsModal} onSubmit={handleSubmit} /> : null}
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => { handleSort('id'); handleChangePage(null, 1); }}>ID</TableCell>
                        <TableCell>Preview</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active'); handleChangePage(null, 1); }} align="left">Active</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('title'); handleChangePage(null, 1); }} align="right">Title</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('brushesNeeded'); handleChangePage(null, 1); }} align="right">Counter</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('brushesNeeded'); handleChangePage(null, 1); }} align="right">Type</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('modifiedBy'); handleChangePage(null, 1); }} align="right">Modified By</TableCell>
                        <TableCell align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataProps.dataProps.map(row => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                <Avatar alt="Aquafresh cover photo"
                                    src={config.API_BASE_URL + '/api/media/preview/' + row.mediaId} style={{ marginRight: '.5rem' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.active ? 'Active' : 'Inactive'}
                            </TableCell>
                            <TableCell align="right">{row.title}</TableCell>
                            <TableCell align="right">{row.brushesNeeded}</TableCell>
                            <TableCell align="right">{getType(row.category)}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => propsModal(row)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                <Button size="small" onClick={() => onDeleteProps(row)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
