import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '../../components/Alerts/Alerts.jsx';
import config from '../../config';

const { GSK_AUTH_URL } = config;

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            Aquafresh
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function SingleSignOn() {
    const classes = useStyles();
    const [error, setError] = useState('');

    const handleClose = () => {
        setError('');
    };

    const handleSubmit = (e) => {
        try {
            e.preventDefault();
            window.location.replace(GSK_AUTH_URL);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                {error ? (
                    <Alert
                        type="error"
                        message={error}
                        open={!!error}
                        handleClose={handleClose}
                    />
                ) : null}
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                   Aquafresh
                </Typography>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                >
                    Sign in
                </Button>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
