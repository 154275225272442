import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import './Dashboard.scss';

const Dashboard = () => {
    const useStyles = makeStyles(theme => ({
        appBarSpacer: theme.mixins.toolbar,
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            maxWidthLg: '100%'
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            height: '100vh'
        },
        fixedHeight: {
            height: '100vh'
        }
    }));
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <StylesProvider>
            <main className="dashboard-wrapper">
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Typography variant="h5" gutterBottom>Dashboard</Typography>

                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={fixedHeightPaper}>
                Dashboard
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </StylesProvider>
    );
};

export default Dashboard;
