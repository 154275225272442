import React, { useEffect, useState, useContext, useCallback } from 'react';
import Alert from '../Alerts/Alerts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import { assetService } from '../../services/index';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import AssetModal from '../AssetModal/AssetModal.jsx';
import { ASSET_CATEGORIES, DEFAULT_ROWS_PER_PAGE } from '../../constants';
import config from '../../config';
import { isNumber } from '../../utils/number.utils';
import moment from 'moment';

import './DanceTable.scss';

const useStyles = makeStyles({
    table: {
    // minWidth: 650,
    }
});

export default function DanceTable() {
    const [page, setPage] = React.useState(1);
    // const [dense, setDense] = React.useState(false);
    const { sort, handleSort } = useSortHook('title');
    const [dances, setDances] = useState({ dances: [] });

    const [danceModelData, openDanceModalData] = useState({
        isOpen: false,
        dance: null
    });

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [success, setSuccess] = useState(null);

    const { selectedLng } = useContext(
        LanguageContext
    );

    const [error, setError] = useState('');
    const classes = useStyles();

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(dances.count);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(dances.count);
    }, [handleNumberOfPages, dances.count]);

    const successAlertMsg = useCallback((msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    }, []);

    /**
   * getAllDances
   * call assetService and get all dances by the selected language
   */
    const getAllDances = useCallback(async () => {
        try {
            if (sort) {
                const lng = selectedLng && selectedLng.id ? selectedLng.id : '';
                let queryParams;
                if (lng) {
                    queryParams =
                    `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&category=${ASSET_CATEGORIES.DANCE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}&langId=${lng}`;
                } else {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&category=${ASSET_CATEGORIES.DANCE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                }
                const data = await assetService.getAllAssets(queryParams);
                const newDances = [...data.data.rows];
                setDances(prevState => {
                    return {
                        ...prevState,
                        dances: newDances,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [pageNumber, sort, selectedLng, setDances]);

    useEffect(() => {
        getAllDances();
        return () => {
        };
    }, [sort, pageNumber, getAllDances, dances.dances.length]);

    const checkIsValid = (data) => {
        if (!data) {
            setError('All data are required');
            return false;
        } else if (!data.title) {
            setError('Missing title');
            return false;
        } else if (!isNumber(data.brushesNeeded)) {
            setError('Counter missing');
            return false;
        } else if (!(data.active > -1) && !(data.active < 2)) {
            setError('Active is not set');
            return false;
        } else if (!data.mediaId) {
            setError('Media not selected');
            return false;
        }
        return true;
    };

    /**
   * addDance
   * call assetService and ad a new background object
   * @param {object} newData newData a background object that should be inserted
   */
    const addDance = async (newData) => {
        try {
            if (!newData) {
                setError('All data are required.');
                return;
            }
            if (!selectedLng || !selectedLng.acronym) {
                setError('Please select a language');
                return;
            }
            newData.langId = selectedLng.id;
            newData.category = ASSET_CATEGORIES.DANCE;
            if (checkIsValid(newData)) {
                newData.brushesNeeded = newData.brushesNeeded.toString();
                const newBackground = await assetService.addAsset(newData);
                setDances(prevState => {
                    const prevData = [...prevState.dances, newBackground.data];
                    return {
                        ...prevState,
                        dances: prevData,
                        count: prevState.count + 1
                    };
                });
                setError('');
                openDanceModalData({});
                getAllDances();
                successAlertMsg('Successfully added new dance');
            }
        } catch (err) {
            openDanceModalData(null);
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    const handleCancel = useCallback(() => {
        setOpenConfirmationModal(null);
    }, [setOpenConfirmationModal]);

    /**
   * deleteDance
   * call assetService and delete a dance
   * @param {object} page dance object that should be deleted
   */
    const deleteDance = useCallback(async (dance) => {
        try {
            await assetService.deleteAsset(dance.id);
            setDances(prevState => {
                const danceList = [...prevState.dances.filter(item => item.id !== dance.id)];
                if (danceList.length === 0 && page > 0) handleChangePage(null, page - 1);
                return {
                    dances: danceList,
                    count: prevState.count - 1
                };
            });
            setError('');
            openDanceModalData({});
            handleCancel();
            getAllDances();
            successAlertMsg(`Successfully deleted dance: ${dance.title}`);
        } catch (err) {
            handleCancel();
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
            handleCancel();
        }
    }, [setDances, setError, openDanceModalData, handleCancel, getAllDances, successAlertMsg, page]);

    const onDeleteDance = useCallback((data) => {
        setOpenConfirmationModal(() => {
            return {
                title: 'Delete dance',
                message: `Are you sure you want delete ${data.title}`,
                isOpen: true,
                data: data,
                handleSubmit: deleteDance,
                handleClose: handleCancel
            };
        });
    }, [setOpenConfirmationModal, deleteDance, handleCancel]);

    /**
   * updateDance
   * call assetService and update dance
   * @param {object} data dance object that should be updated
   */
    const updateDance = async (data) => {
        try {
            if (checkIsValid(data)) {
                data.langId = selectedLng.id;
                data.brushesNeeded = data.brushesNeeded.toString();
                const updatedDance = await assetService.editAsset(data.id, data);
                setDances(prevState => {
                    const prevDances = [...prevState.dances];
                    return {
                        ...prevState,
                        dances: prevDances.map(item => item.id === data.id ? updatedDance.data : item)
                    };
                });
                openDanceModalData({});
                setError('');
                successAlertMsg(`Successfully updated dance: ${data.title}`);
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err && err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    const closeDanceModal = () => {
        openDanceModalData({});
    };

    const handleSubmit = (dance) => {
        if (danceModelData && danceModelData.data) {
            updateDance(dance);
        } else {
            addDance(dance);
        }
    };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    /**
   * backgroundModal
   * open backgroundModal
   */
    const backgroundModal = (dance) => {
        if (!selectedLng || !selectedLng.id) {
            setError('Please select a language.');
            return;
        }
        if (!dance) {
            openDanceModalData(() => {
                return {
                    isOpen: true,
                    data: null,
                    title: 'Add new dance',
                    coverPhoto: 'Dance cover photo',
                    coverFile: 'Dance file'
                };
            });
        } else {
            openDanceModalData(prevState => {
                return {
                    ...prevState,
                    isOpen: true,
                    data: dance,
                    title: 'Edit dance',
                    coverPhoto: 'Dance cover photo',
                    coverFile: 'Dance file'
                };
            });
        }
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null}
            <Button className="add-text-btn" size="small" variant="outlined" color="default" onClick={() => backgroundModal()}>Add</Button>
            { danceModelData && danceModelData.isOpen ? <AssetModal {...danceModelData} onClose={closeDanceModal} onSubmit={handleSubmit} /> : null }
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => { handleSort('id'); handleChangePage(null, 1); }}>ID</TableCell>
                        <TableCell>Preview</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active'); handleChangePage(null, 1); }} align="left">Active</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('title'); handleChangePage(null, 1); }} align="right">Title</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('brushesNeeded'); handleChangePage(null, 1); }} align="right">Counter</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('modifiedBy'); handleChangePage(null, 1); }} align="right">Modified By</TableCell>
                        <TableCell align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dances.dances.map(row => (
                        <TableRow key={row.id}>
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell>
                                <Avatar alt="Aquafresh cover photo"
                                    src={config.API_BASE_URL + '/api/media/preview/' + row.mediaId} style={{ marginRight: '.5rem' }} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.active ? 'Active' : 'Inactive'}
                            </TableCell>
                            <TableCell align="right">{row.title}</TableCell>
                            <TableCell align="right">{row.brushesNeeded}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => backgroundModal(row)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                <Button size="small" onClick={() => onDeleteDance(row)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>

        </React.Fragment>
    );
}
