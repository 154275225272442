import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MediaModal from '../MediaModal/MediaModal.jsx';
import MediaItem from '../MediaModal/MediaItem.jsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { mediaService } from '../../services/index';
import { MISC_TYPE, CHARACTER_PROPS_TYPE } from '../../constants';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

const AssetModal = ({
    onClose,
    isOpen = false,
    data,
    onSubmit,
    title,
    isProp,
    characters,
    isMiscs
}) => {
    const [assetData, setAssetData] = useState({
        title: '',
        brushesNeeded: 0,
        active: 0,
        cover: null,
        langId: '',
        settings: '',
        imageName: '',
        fileName: '',
        mediaId: null,
        assetMedia: null
    });

    const [isOpenMediaModal, setIsOpenMediaModal] = useState(false);
    const classes = useStyles();
    const [selectedCharacters, setSelectedCharacters] = useState([]);
    const [type, setType] = useState('');
    const [miscType, setMiscsType] = useState('');

    React.useEffect(() => {
        let imageName;
        let fileName;
        if (data && data.image) {
            imageName = data.image.split('/')[data.image.split('/').length - 1];
        }
        if (data && data.fileSrc) {
            fileName = data.fileSrc.split('/')[data.fileSrc.split('/').length - 1];
        }
        setAssetData(() => {
            return {
                ...data,
                active: data && data.active ? data.active : 0,
                imageName: imageName,
                fileName: fileName
            };
        });

        if (data && data.characters && data.characters.length > 0) {
            setSelectedCharacters(data.characters);
        }

        if (data && data.category) {
            setType(data.category);
        }
        if (isMiscs && data && data.type) {
            setMiscsType(data.type);
        }
        if (data && data.mediaId) getMedia(data.mediaId);
    }, [data, isMiscs]);

    React.useEffect(() => {
        return () => setAssetData({
            title: '',
            brushesNeeded: 0,
            active: 0,
            cover: null,
            langId: '',
            settings: '',
            imageName: '',
            fileName: '',
            mediaId: null,
            assetMedia: null
        });
    }, []);

    const handleChange = e => {
        e.persist();
        if (e.target.name === 'cover' || e.target.name === 'file') {
            if (e.target.files[0]) {
                setAssetData(prevSate => {
                    const newState = { ...prevSate };
                    return {
                        ...newState,
                        [e.target.name]: e.target.files[0],
                        [e.target.name + 'Img']: URL.createObjectURL(e.target.files[0])
                    };
                });
            }
        } else if (e.target.name === 'brushesNeeded') {
            setAssetData(prevState => {
                return {
                    ...prevState,
                    brushesNeeded: parseInt(e.target.value)
                };
            });
        } else {
            setAssetData(prevSate => {
                const newState = { ...prevSate };
                return {
                    ...newState,
                    [e.target.name]: e.target.value
                };
            });
        }
    };

    const getMedia = async (id) => {
        const media = await mediaService.getMedia(id);
        setAssetData(prevSate => {
            return {
                ...prevSate,
                assetMedia: media.data
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(assetData, selectedCharacters, type, miscType);
    };

    const openMediaModal = () => {
        setIsOpenMediaModal(true);
    };

    const closeMediaModal = () => {
        setIsOpenMediaModal(false);
    };

    const onMediaCardClick = useCallback((media) => {
        setAssetData(prevSate => {
            return {
                ...prevSate,
                assetMedia: media,
                mediaId: media.id
            };
        });
    }, [setAssetData]);

    const handleCharacters = useCallback((e) => {
        setSelectedCharacters(e.target.value);
    }, [setSelectedCharacters]);

    const handleChangeTypes = useCallback((e) => {
        if (isMiscs) {
            setMiscsType(e.target.value);
        } else {
            setType(e.target.value);
        }
    }, [setType, setMiscsType, isMiscs]);

    const renderPropsCharacters = () => {
        return (
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-chip-label">Choose Character</InputLabel>
                    <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        value={selectedCharacters}
                        onChange={handleCharacters}
                        name="character"
                        multiple
                        fullWidth
                        input={<Input />}
                    >
                        {
                            characters.map(item => {
                                return (
                                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <br />
            </>
        );
    };

    const renderPropsTypes = () => {
        return (
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">Prop Types</InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={type}
                        onChange={handleChangeTypes}
                        name="type"
                        fullWidth
                    >
                        {
                            CHARACTER_PROPS_TYPE.map(item => {
                                return (
                                    <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <br />
            </>
        );
    };

    const renderMiscsTypes = () => {
        return (
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">Misc Types</InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={miscType}
                        onChange={handleChangeTypes}
                        name="type"
                        fullWidth
                    >
                        {
                            MISC_TYPE.map(item => {
                                return (
                                    <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <br />
            </>
        );
    };

    return (
        <div>
            {isOpenMediaModal ? <MediaModal onMediaCardClick={onMediaCardClick} isOpen={isOpenMediaModal} onClose={closeMediaModal}/> : null}
            <Dialog
                open={isOpen}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">{title || ''}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="normal"
                            id="title"
                            label="Title"
                            type="text"
                            name="title"
                            value={assetData && assetData.title ? assetData.title : ''}
                            fullWidth
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            id="brushesNeeded"
                            label="Counter"
                            type="number"
                            name="brushesNeeded"
                            value={
                                (assetData && assetData.brushesNeeded) || (assetData.brushesNeeded === 0)
                                    ? assetData.brushesNeeded
                                    : ''
                            }
                            // inputProps={{ min: 0 }}
                            fullWidth
                            onChange={handleChange}
                        />
                        <br />
                        <br />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={assetData && assetData.active === 1 ? assetData.active : 0}
                            onChange={handleChange}
                            name="active"
                        // fullWidth
                        >
                            <MenuItem value={0}>Inactive</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                        </Select>
                        <br />
                        {
                            isProp ? <>{renderPropsCharacters()} {renderPropsTypes() }</> : null
                        }
                        {
                            isMiscs ? <>{renderMiscsTypes()}</> : null
                        }
                        <br />
                        {assetData && assetData.assetMedia && assetData.assetMedia.id
                            ? <MediaItem data={assetData.assetMedia} />
                            : null}
                        <Button variant="contained" component="label" onClick={openMediaModal}>
                                Choose Media
                        </Button>

                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
            Cancel
                        </Button>
                        <Button type="submit" color="primary">
            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

AssetModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    isProp: PropTypes.bool,
    characters: PropTypes.array,
    isMiscs: PropTypes.bool
};

export default AssetModal;
