import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { sessionService } from '../../sessionServices/sessionServices';
import { userService } from '../../services/user.service';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import { UserContext } from '../../contexts/userContext';

export default function SettingsNav() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const { setSelectedLanguage } = useContext(
        LanguageContext
    );

    const [user, setUser] = useContext(UserContext);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        userService.logout().then(() => {
            sessionService.destroy();
            setUser(null);
            localStorage.removeItem('selectedLng');
            setSelectedLanguage(null);
            history.push('/login');
        });
    };

    return (
        <div>
            <Button aria-controls="simple-menu" color="inherit" aria-haspopup="true" onClick={handleClick}>
                <SettingsIcon/>
            </Button>
            {user && user.username ? user.username : ''}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={logout}><ExitToAppIcon />{' '}Logout</MenuItem>
            </Menu>
        </div>
    );
}
