import React, { useState, createContext, useEffect, useCallback } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = props => {
    const [languages, setLanguages] = useState([]);
    const [selectedLng, setSelectedLng] = useState();

    const setSelectedLanguage = useCallback(language => {
        localStorage.setItem('selectedLng', JSON.stringify(language));
        let lng = localStorage.getItem('selectedLng');
        lng = lng ? JSON.parse(lng) : null;
        setSelectedLng(lng);
    }, [setSelectedLng]);

    useEffect(() => {
        let lng = localStorage.getItem('selectedLng');
        lng = lng ? JSON.parse(lng) : null;
        setSelectedLng(lng);
    }, [languages.length]);

    return (
    // passing state and function that set state
        <LanguageContext.Provider value={{ languages, setLanguages, selectedLng, setSelectedLanguage }}>
            {props.children}
        </LanguageContext.Provider>
    );
};
