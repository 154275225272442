import React, { useEffect, useContext, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import { languageService } from '../../services/index';

export default function ListOfLanguages() {
    const { languages, setLanguages, selectedLng, setSelectedLanguage } = useContext(
        LanguageContext
    );
    const [anchorEl, setAnchorEl] = React.useState(null);

    const fetchData = useCallback(async () => {
        try {
            const data = await languageService.getAllLanguagesIncludingInactive();
            setLanguages(prevState => data.data);
            if (!selectedLng || !selectedLng.id) {
                data.data.forEach(item => {
                    if (item.default && item.default === 1) {
                        setSelectedLanguage(item);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [setLanguages, selectedLng, setSelectedLanguage]);

    useEffect(() => {
        fetchData();
    }, [languages.length, fetchData]);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLng = lng => {
        setSelectedLanguage(lng);
        handleClose();
    };

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                color="inherit"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {selectedLng ? `${selectedLng.name} ${selectedLng.active === 0 ? ' - inactive' : ''}` : 'Languages'}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languages && languages.length > 0
                    ? languages.map(item => {
                        return (
                            <MenuItem key={item.id} onClick={() => changeLng(item)}>
                                {item.name} {item.active === 0 ? ' - inactive' : '' }
                            </MenuItem>
                        );
                    })
                    : null}
            </Menu>
        </div>
    );
}
