import axiosAPIUpload from '../httpService/httpAxiosService';
import axiosAPI from '../httpService/httpAxiosService';

export const mediaService = {
    addMedia,
    editMedia,
    deleteMedia,
    getAllMedia,
    getMedia,
    getAllMediaWithoutParams,
    getPreview,
    getFile
};

function addMedia(data, config) {
    return axiosAPIUpload.post('/api/media', data, config);
}

function editMedia(id, data) {
    return axiosAPIUpload.post(`/api/media/${id}`, data);
}

function deleteMedia(id) {
    return axiosAPIUpload.delete(`/api/media/media/${id}`);
}

function getAllMedia(queryParams) {
    return axiosAPIUpload.get(`/api/media${queryParams}`);
}

function getAllMediaWithoutParams() {
    return axiosAPIUpload.get('/api/media/all');
}

function getMedia(id) {
    return axiosAPIUpload.get(`/api/media/${id}`);
}

function getPreview(id, cancelToken) {
    return axiosAPIUpload.get(`/api/media/preview/${id}`, cancelToken);
}

function getFile(id, cancelToken) {
    return axiosAPI.get(`/api/media/file/${id}`, cancelToken);
}
