import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Alert from '../Alerts/Alerts.jsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx';
import { pageService } from '../../services/index';
import { LanguageContext } from '../../contexts/languagesContext.jsx';
import useSortHook from '../../customHooks/useSortHook';
import usePaginationHook from '../../customHooks/usePaginationHook';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants';

import './PagesTable.scss';

const useStyles = makeStyles({
    table: {
    // minWidth: 650,
    }
});

export default function PagesTable() {
    const [page, setPage] = React.useState(1);
    // const [dense, setDense] = React.useState(false);
    const { sort, handleSort } = useSortHook('title');
    const [pages, setPages] = useState({ pages: [] });

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [success, setSuccess] = useState(null);

    const { selectedLng } = useContext(
        LanguageContext
    );

    const { numberOfPages, handleNumberOfPages } = usePaginationHook(pages.count);

    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        handleNumberOfPages(pages.count);
    }, [handleNumberOfPages, pages.count]);

    const [error, setError] = useState('');
    const classes = useStyles();

    const history = useHistory();

    /**
   * getAllPages
   * call pageService and get all texts by the selected language
   */
    const getAllPages = useCallback(async () => {
        try {
            if (sort) {
                const lng = selectedLng && selectedLng.id ? selectedLng.id : '';
                let queryParams;
                if (lng) {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}&langId=${lng}`;
                } else {
                    queryParams = `?page=${pageNumber}&pageSize=${DEFAULT_ROWS_PER_PAGE}&order_field=${Object.keys(sort)[0]}&order=${Object.values(sort)[0]}`;
                }
                const data = await pageService.getAllPages(queryParams);
                const newPages = [...data.data.rows];
                setPages(prevState => {
                    return {
                        ...prevState,
                        pages: newPages,
                        count: data.data.count
                    };
                });
            }
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
        }
    }, [pageNumber, sort, selectedLng, setPages]);

    useEffect(() => {
        getAllPages();
        return () => {
        };
    }, [sort, pageNumber, getAllPages]);

    /**
   * deletePage
   * call pageService and delete a page
   * @param {object} data page object that should be deleted
   */
    const deletePage = async (data) => {
        try {
            await pageService.deletePage(data.id);
            setPages(prevState => {
                const pageList = [...prevState.pages.filter(item => item.id !== data.id)];
                if (pageList.length === 0 && page > 0) handleChangePage(null, page - 1);
                return {
                    pages: pageList,
                    count: prevState.count - 1
                };
            });
            setError('');
            handleCancel();
            successAlertMsg(`Successfully deleted page: ${data.title}`);
        } catch (err) {
            if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
                setError(err.response.data.error.message);
            } else if (err.response && err.response.statusText) {
                setError(err.response.statusText);
            }
            handleCancel();
        }
    };

    const onDeletePage = (data) => {
        setOpenConfirmationModal(() => {
            return {
                title: 'Delete page',
                message: `Are you sure you want delete ${data.title}`,
                isOpen: true,
                data: data,
                handleSubmit: deletePage,
                handleClose: handleCancel
            };
        });
    };

    const handleCancel = () => {
        setOpenConfirmationModal(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageNumber(newPage - 1);
    };

    /**
   * handleClose
   * close alert modal
   */
    const closeAlertMessage = () => {
        setError('');
        setSuccess(null);
    };

    const successAlertMsg = (msg) => {
        setSuccess(prevState => {
            return {
                ...prevState,
                message: msg,
                isOpen: true
            };
        });
    };

    const goToPage = (id) => {
        if (!selectedLng || !selectedLng.id) {
            setError('Please select a language.');
            return;
        }
        !id ? history.push('/dashboard/pages/add') : history.push(`/dashboard/pages/edit/${id}`);
    };

    return (
        <React.Fragment>
            {error ? <Alert type="error" message={error} open={!!error} handleClose={closeAlertMessage} /> : null}
            {success && success.isOpen ? <Alert type="success" message={success.message} open={success.isOpen } handleClose={closeAlertMessage} /> : null}
            {openConfirmationModal && openConfirmationModal.isOpen ? <ConfirmationModal {...openConfirmationModal}/> : null}
            <Button className="add-text-btn" size="small" variant="outlined" color="default" onClick={() => goToPage()}>Add</Button>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className="users-table-th" onClick={() => { handleSort('id'); handleChangePage(null, 1); }}>ID</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('active');handleChangePage(null, 1); }} align="left">Active</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('title'); handleChangePage(null, 1); }} align="right">Title</TableCell>
                        <TableCell className="users-table-th" onClick={() => { handleSort('key'); handleChangePage(null, 1); }} align="right">Key</TableCell>
                        <TableCell align="right">Modified By</TableCell>
                        <TableCell align="right">Modified At</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pages.pages.map(row => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell component="th" scope="row">
                                {row.active ? 'Active' : 'Inactive'}
                            </TableCell>
                            <TableCell align="right">{row.title}</TableCell>
                            <TableCell align="right">{row.key}</TableCell>
                            <TableCell align="right">{row['Modifier.name']}</TableCell>
                            <TableCell align="right">{moment(row.updatedAt).format('DD.MM.YYYY, HH:mm')}</TableCell>
                            <TableCell align="right">
                                <Button size="small" onClick={() => goToPage(row.id)} className={classes.margin}>
                                    <EditIcon />
                                </Button>
                                <Button size="small" onClick={() => onDeletePage(row)} className={classes.margin}>
                                    <DeleteOutlineIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        {numberOfPages > 0
                            ? <TableCell colSpan={9}>
                                <Pagination
                                    className="table-pagination"
                                    count={numberOfPages}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </TableCell>
                            : null }
                    </TableRow>
                </TableBody>
            </Table>

        </React.Fragment>
    );
}
