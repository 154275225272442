import axiosAPI from '../httpService/httpAxiosService';

export const assetService = {
    addAsset,
    editAsset,
    deleteAsset,
    getAllAssets,
    getAsset,
    getAll
};

function addAsset(data) {
    return axiosAPI.post('/api/asset', data);
}

function editAsset(id, data) {
    return axiosAPI.post(`/api/asset/${id}`, data);
}

function deleteAsset(id) {
    return axiosAPI.delete(`/api/asset/${id}`);
}

function getAllAssets(queryParams) {
    return axiosAPI.get(`/api/asset/pageable${queryParams}`);
}

function getAll(queryParams) {
    return axiosAPI.get(`/api/asset/${queryParams}`);
}

function getAsset(id, cancelToken) {
    return axiosAPI.get(`/api/asset/${id}`, cancelToken);
}