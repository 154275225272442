import { useState, useEffect } from 'react';

const useSortHook = (sortBy) => {
    const [sort, setSort] = useState();
    useEffect(() => {
        handleSort(sortBy);
    }, [sortBy]);

    const handleSort = (sortBy) => {

        setSort(prevState => {
            let newSortBy = { ...prevState };
            if (newSortBy[sortBy] === 'asc') {
                newSortBy = {};
                newSortBy[sortBy] = 'desc';
            } else {
                newSortBy = {};
                newSortBy[sortBy] = 'asc';
            }
            return newSortBy;
        });
    };

    return { sort, handleSort };
};

export default useSortHook;